export const localStorageNames = {
    __USER_DATA: "jkYj_LP_hjU43",
    __PLAN_DATA: "Amns_BR_jyw12",
}

export const telPhoneCountryCode = [
    "+91",
    "+66",
    "+1-869",
    "+60",
    "+226",
    "+1-246",
    "+354",
    "+670",
    "+501",
    "+377",
    "+358",
    "+254",
    "+681",
    "+692",
    "+53",
    "+1-664",
    "+680",
    "+34",
    "+508",
    "+235",
    "+248",
    "+1-649",
    "+995",
    "+240",
    "+54",
    "+1",
    "+676",
    "+238",
    "+268",
    "+1-868",
    "+423",
    "+590",
    "+691",
    "+1-340",
    "+212",
    "+44-1624",
    "+351",
    "+674",
    "+46",
    "+993",
    "+389",
    "+227",
    "+95",
    "+683",
    "+290",
    "+1-684",
    "+229",
    "+252",
    "+47",
    "+242",
    "+41",
    "+1-784",
    "+356",
    "+47",
    "+355",
    "+246",
    "+965",
    "+218",
    "+590",
    "+212",
    "+64",
    "+262",
    "+675",
    "+853",
    "+686",
    "+383",
    "+86",
    "+370",
    "+994",
    "+371",
    "+298",
    "+82",
    "+595",
    "+57",
    "+375",
    "+504",
    "+92",
    "+886",
    "+1-345",
    "+91",
    "+44-1534",
    "+353",
    "+679",
    "+973",
    "+224",
    "+30",
    "+421",
    "+63",
    "+599",
    "+856",
    "+266",
    "+94",
    "+599",
    "+677",
    "+32",
    "+249",
    "+672",
    "+58",
    "+216",
    "+590",
    "+221",
    "+964",
    "+998",
    "+1-767",
    "+40",
    "+245",
    "+49",
    "+1-264",
    "+382",
    "+880",
    "+505",
    "+374",
    "+592",
    "+81",
    "+253",
    "+7",
    "+598",
    "+1-268",
    "+996",
    "+297",
    "+971",
    "+597",
    "+1-441",
    "+223",
    "+970",
    "+90",
    "+358-18",
    "+36",
    "+870",
    "+243",
    "+262",
    "+502",
    "+48",
    "+387",
    "+850",
    "+31",
    "+44",
    "+233",
    "+687",
    "+359",
    "+256",
    "+51",
    "+372",
    "+420",
    "+93",
    "+45",
    "+291",
    "+968",
    "+966",
    "+500",
    "+672",
    "+0055",
    "+672",
    "+211",
    "+56",
    "+265",
    "+241",
    "+385",
    "+386",
    "+855",
    "+260",
    "+962",
    "+591",
    "+967",
    "+352",
    "+381",
    "+960",
    "+257",
    "+1-809",
    "+20",
    "+33",
    "+1-284",
    "+1",
    "+255",
    "+1-670",
    "+232",
    "+690",
    "+98",
    "+974",
    "+1721",
    "+509",
    "+688",
    "+250",
    "+27",
    "+258",
    "+594",
    "+84",
    "+1-242",
    "+1-671",
    "+44-1481",
    "+376",
    "+65",
    "+1",
    "+378",
    "+992",
    "+1-473",
    "+972",
    "+682",
    "+500",
    "+357",
    "+593",
    "+977",
    "+689",
    "+350",
    "+261",
    "+61",
    "+506",
    "+62",
    "+239",
    "+1-787",
    "+267",
    "+380",
    "+379",
    "+961",
    "+262",
    "+596",
    "+228",
    "+264",
    "+263",
    "+61",
    "+237",
    "+231",
    "+963",
    "+55",
    "+7",
    "+373",
    "+269",
    "+61",
    "+503",
    "+220",
    "+299",
    "+976",
    "+39",
    "+236",
    "+52",
    "+213",
    "+678",
    "+852",
    "+222",
    "+251",
    "+1-758",
    "+234",
    "+1-876",
    "+230",
    "+244",
    "+43",
    "+507",
    "+975",
    "+673",
    "+685",
    "+225"
]