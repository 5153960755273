import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { MdPlayArrow, MdPause } from "react-icons/md";
import { useStore } from "../../../../utility/hooks/UseStore";
import pause_icon from "../../../../assets/icons/tl_pause.svg";
import play_icon from "../../../../assets/icons/tl_play.svg";
import { formatTimeToMinSecMili } from "../../../../utility/videoEditorUtils";
import minus_icon from "../../../../assets/Zoom_out.svg";
import plus_icon from "../../../../assets/Zoom_in.svg";

const MARKINGS = [
  {
    interval: 5000,
    color: "#3B3B4F",
    size: 16,
    width: 1,
  },
  {
    interval: 1000,
    color: "#3B3B4F",
    size: 8,
    width: 1,
  },
];



const SeekPlayer = observer((props) => {

  const mainStore = useStore()
  const store = mainStore.videoStore

  const Icon = store.playing ? MdPause : MdPlayArrow;
  let ImageIcon;
  if (store.playing) {
    ImageIcon = <img src={pause_icon} alt="pause" />;
  } else {
    ImageIcon = <img src={play_icon} alt="play" />;
  }
  const formattedTime = formatTimeToMinSecMili(store.currentTimeInMs);
  const formattedMaxTime = formatTimeToMinSecMili(store.maxTime);

  const [volume, setVolume] = useState(10);
  const videoRef = useRef(null);

  const handleVolumeChange = (e) => {
    store.handleTimeLineZoom(Number(e.target.value));
    // const rangeInput = document.getElementById('rangeForTimeline');
    // const progress = (e.target.value / rangeInput.max) * 100;
    // rangeInput.style.background = `linear-gradient(to right, #5446D0 0%, #5446D0 ${progress / 2}%, #ABABC0 ${progress / 2}%, #ABABC0 100%)`;
    // rangeInput.style.height = '4px';
    // rangeInput.style.marginTop = '8px';
  };

  const handleMinusClick = () => {
    if (store.timeLineZoom > 50) {
      const zoom = store.timeLineZoom - 2;
      store.handleTimeLineZoom(zoom);
    }
  };

  const handlePlusClick = () => {
    if (store.timeLineZoom < 200) {
      const zoom = store.timeLineZoom + 2;
      store.handleTimeLineZoom(zoom);
    }
  };

  // document.addEventListener('loadeddata', function () {
  // useEffect(() => {
  //   const rangeInput = document.getElementById('rangeForTimeline');
  //   // function updateRangeColor() {
  //   const progress = (store.timeLineZoom / rangeInput.max) * 100;
  //   rangeInput.style.background = `linear-gradient(to right, #5446D0 0%, #5446D0 ${progress - 50}%, #ABABC0 ${progress - 50}%, #ABABC0 100%)`;
  //   rangeInput.style.height = '4px';
  //   rangeInput.style.marginTop = '8px';
  //   // }

  //   // rangeInput.addEventListener('input', updateRangeColor);

  //   // Initialize the color on page load
  //   // updateRangeColor();
  // }, [])
  // });


  const [isDrag, setIsDrag] = useState(false)


  return (

    <div className="seek-player flex flex-col w-full ">
      <div className="flex justify-between items-center relative">
        <div className={`${props?.showTimeline ? ' mx-auto' : 'w-[100%]'} `}>
          <div className="flex flex-row justify-center items-center px-2 2xl:mt-[5px] font-Inter font-medium text-sm text-[#ABABC0]">
            <span className="w-[55px]">{formattedMaxTime}</span>
            <button
              className="rounded  px-2 py-2 outline-none"
              onClick={() => {
                store.setPlaying(!store.playing);
              }}
            >
              {ImageIcon && ImageIcon}
            </button>
            <span className="w-[40px] text-end">{formattedTime}</span>
          </div>
        </div>
        {props?.showTimeline && <div className="flex justify-between absolute right-[-80px] top-[50%] 2xl:top-[60%]  translate-x-[-50%] translate-y-[-50%] gap-[10px]  p-[12px] border-l border-[#2C2D3C]">
          <div className="flex 22xl:gap-[22px] justify-between">
            <button className="outline-none hover:bg-[#2b2b32] px-[8px] py-[5px] rounded-[5px]" onClick={handleMinusClick}><img src={minus_icon} alt="minus" className=" w-[15px] h-auto object-contain" /></button>
            {/* <input
              id="rangeForTimeline"
              type="range"
              className={`bg-[#ABABC0] w-full h-[4px] mt-[8px] outline-none ${isDrag ? "cursor-grabbing" : "cursor-grab"}`}
              min={50}
              max={200}
              step={2}
              onMouseDown={(e) => { setIsDrag(true) }}
              onMouseUp={(e) => { setIsDrag(false) }}
              value={store.timeLineZoom}
              onChange={handleVolumeChange}
            /> */}
            <button className="outline-none hover:bg-[#2b2b32] px-[8px] py-[5px] rounded-[5px]" onClick={handlePlusClick}><img src={plus_icon} alt="plus" className="w-[15px] h-auto object-contain" /></button>
          </div>
          <button onClick={() => { store.handleTimeLineZoom(100) }} className="font-Inter font-medium text-sm text-[#ABABC0]">
            Fit Timeline
          </button>
        </div>}
      </div>
      {/* <div className="border-b border-[#2C2D3C]">
        <ScaleRangeInput
          max={store.maxTime}
          value={store.currentTimeInMs}
          onChange={(value) => {
            store.handleSeek(value);
          }}
          height={40}
          markings={MARKINGS}
          backgroundColor="transparent"
        />
      </div> */}
    </div>
  );
});


export default SeekPlayer;