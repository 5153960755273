import axios from "axios";
import { localStorageNames } from "./constants"
import { decryptData } from "../utility/utils";
const { __USER_DATA } = localStorageNames

const apiService = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
})

apiService.interceptors.request.use(
    (config) => {
        let token
        if (localStorage.getItem(__USER_DATA)) {
            token = decryptData(localStorage.getItem(__USER_DATA)).token
        } else {
            token = false
        }

        if (config.headers.Authorization) {
            if (config.headers['Authorization'] === 'NoToken') { }
        } else {
            config.headers.Authorization = `${token}`;
        }

        if (config.headers['Content-Type'] === 'multipart/form-data') {
            config.headers['Content-Type'] = `multipart/form-data`;
        } else {
            config.headers['Content-Type'] = 'application/json';
        }

        return config
    },
    (error) => {
        console.log(error);

        // return Promise.reject(error)
    }
)

export default apiService