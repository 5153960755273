import { action, comparer, makeAutoObservable, set } from 'mobx';
import { fabric } from 'fabric';
import { getUid, isHtmlAudioElement, isHtmlImageElement, isHtmlVideoElement } from '../utility/videoEditorUtils';
import anime from 'animejs';
import { FabricUitls } from '../utility/fabricUtils';
import { FFmpeg } from '@ffmpeg/ffmpeg';
// import { toBlobURL } from '@ffmpeg/util';
import deleteicone from "../assets/icons/delete.svg";
import animationicon from "../assets/icons/animation.svg";
import copy from "../assets/icons/copy.svg";
import merge_icon from "../assets/icons/merge.svg";
import { decryptData, encryptData, errorStatusHandler, isEncryptModeOn } from '../utility/utils';
import apiService from '../configs/axios';
import toast from 'react-hot-toast';
import CustomRectButton from '../utility/customFabricClass/customButton';
import CustomRectText from '../utility/customFabricClass/customText';


class CustomButton extends fabric.Group {

    linkUrl;
    scaleX;
    scaleY;


    constructor(
        text,
        options) {


        const rectHeight = options.height || 40;


        const rect = new fabric.Rect({
            width: options.width,
            height: rectHeight,
            fill: options.backgroundColor || 'blue',
            stroke: options.stroke || 'black',
            strokeWidth: options.strokeWidth || 0,
            rx: options.borderRadius || 0,
            hasControls: false,
            originX: "center",
            originY: "center",
            opacity: options.btnOpacity,
        });


        const buttonText = new fabric.IText(text, {
            fontSize: options.fontSize || 16,
            fill: options.textColor || 'white',
            textAlign: 'center',
            width: options.width,
            height: rectHeight,
            editable: true,
            underline: options.underline,
            fontFamily: options.fontFamily,
            fontStyle: "italic",
            fontWeight: options.fontWeight,
            originX: "center",
            originY: "center",
            selectable: true,
            opacity: options.txtOpacity,
            charSpacing: options.charSpacing || 0,
            lineHeight: options.lineHeight || 1,
        });

        super([rect, buttonText], {
            ...options,
            noScaleCache: false,
            selectable: true,
            cornerSize: 8,
            cornerColor: '#3B3B4F',
            cornerStrokeColor: '#3B3B4F',
            transparentCorners: false,
            borderColor: '#3B3B4F',
            lockRotation: true,
            lockUniScaling: true,
            width: options.width,
            lockScalingFlip: true,
        });


        this.linkUrl = options.linkUrl;

        this.scaleX = options.scaleX ?? 1;
        this.scaleY = options.scaleY ?? 1;

    }
}

class CustomText extends fabric.Group {


    scaleX;
    scaleY;

    constructor(text, options) {
        const rectHeight = options.height || 40;

        const rect = new fabric.Rect({
            width: options.width,
            height: rectHeight,
            fill: options.backgroundColor || '',
            stroke: options.stroke || '',
            strokeWidth: options.strokeWidth || 1,
            rx: options.borderRadius || 0,
            hasControls: false,
            originX: "center",
            originY: "center",
            opacity: options.btnOpacity,
            angle: options.angle
        });

        const TText = new fabric.IText(text, {
            fontSize: options.fontSize || 16,
            fill: options.textColor || 'white',
            textAlign: 'center',
            width: options.width,
            height: rectHeight,
            editable: true,
            underline: options.underline,
            fontFamily: options.fontFamily,
            fontStyle: options.fontStyle,
            fontWeight: options.fontWeight,
            originX: "center",
            originY: "center",
            selectable: true,
            lineHeight: options.lineHeight || 1,
            charSpacing: options.charSpacing || 0,
            opacity: options.txtOpacity,
            stroke: options.outline,
            strokeWidth: options.outlineWidth || 1,
            angle: options.angle
        });

        super([rect, TText], {
            ...options,
            noScaleCache: false,
            selectable: true,
            cornerSize: 8,
            cornerColor: '#3B3B4F',
            cornerStrokeColor: '#3B3B4F',
            transparentCorners: false,
            borderColor: '#3B3B4F',
            lockRotation: false,
            lockUniScaling: false,
            width: options.width,
        });

        this.scaleX = options.scaleX ?? 1;
        this.scaleY = options.scaleY ?? 1;


        // const originalSet = this.set.bind(this);
        // this.set = (key, value) => {
        // 	originalSet(key, value);

        // 	if (key === 'scaleX' || key === 'scaleY') {
        // 		const newWidth = this.width * this.scaleX;
        // 		const newHeight = this.height * this.scaleY;
        // 	}

        // 	return this;
        // };
    }
}

export class VideoStore {
    canvas
    backgroundColor

    selectedMenuOption
    audios
    videos
    images
    files

    oldVideoID
    videoConfigs = [];
    textConfigs = [];
    textObject = null;
    inputText = '';
    editorElements
    selectedElement;
    maxTime
    audioDurationMs
    animations
    animationTimeLine
    playing
    currentKeyFrame
    fps
    possibleVideoFormats = ['mp4', 'webm'];
    selectedVideoFormat;
    history
    historyIndex
    updatedVideoWidth
    updatedVideoHeight
    btnTop
    btnLeft
    videoDuration
    textTop
    textLeft
    timeLineZoom
    buttonConfigs = [];
    ExcelListForSingleCampaign = [];

    videoURL;
    singleCampaignData
    campaignList
    volumeOfVideo
    videoSizePercentage = 0

    constructor() {
        this.canvas = null;
        this.videos = [];
        this.images = [];
        this.audios = [];
        this.files = [];
        this.oldVideoID = false;
        this.editorElements = [];
        this.backgroundColor = '#111111';
        this.maxTime = 30;
        this.audioDurationMs = 30;
        this.playing = false;
        this.currentKeyFrame = 1;
        this.selectedElement = null;
        this.fps = 60;
        this.animations = [];
        this.animationTimeLine = anime?.timeline();
        this.selectedMenuOption = 'NoSelectedItem';
        this.selectedVideoFormat = 'mp4';
        this.updatedVideoHeight = 0;
        this.updatedVideoWidth = 960;
        this.btnTop = 0;
        this.btnLeft = 0;
        this.videoDuration = 0;
        this.textLeft = 0;
        this.textTop = 0;
        this.history = [];
        this.timeLineZoom = 100;
        this.historyIndex = -1;
        this.videoURL = "";
        this.campaignList = []
        this.singleCampaignData = []
        this.volumeOfVideo = 1
        this.ExcelListForSingleCampaign = []
        this.videoSizePercentage = 0
        this.encryptMode = isEncryptModeOn()
        makeAutoObservable(this, {
            saveHistory: action,
        });
    }

    get currentTimeInMs() {
        return this.currentKeyFrame * 1000 / this.fps;
    }

    // campaign Create with this function
    async CreateCampaign({ formdata, loader, setCreateCampaignModel, setCampaignListFromStore, limit , page }) {
        try {
            const CreateCampaignResponse = await apiService.post(`/user/campaign`, formdata, { headers: { "Content-Type": "multipart/form-data" } })
            const FinalCreateCampaignResponse = await CreateCampaignResponse.data
            if (FinalCreateCampaignResponse.success === true) {
                toast.success(FinalCreateCampaignResponse.message)
                loader(false)
                setCreateCampaignModel(false)
                // window.location.reload()
                this.getCampaignList({ setCampaignListFromStore,loader,limit , page })
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response.data.error)
            }
        }
    }

    // get All Campaign
    async getCampaignList({ setCampaignListFromStore, loader, limit , page , viewMore }) {
        try {
            const campaignListResponse = await apiService.get(`/user/campaign?limit=${limit}&page=${page}`)
            const FinalCampaignListResponse = await campaignListResponse?.data
            if (FinalCampaignListResponse.success) {
                // this.campaignList = this.encryptMode ? decryptData(FinalCampaignListResponse?.data) : FinalCampaignListResponse?.data
                // const campaign = [...this.campaignList?.campaign || [],  ...FinalCampaignListResponse?.data?.campaign || [],];
    
                // this.campaignList = {...FinalCampaignListResponse?.data,campaign,};
                const existingCampaignIDs = new Set(this.campaignList?.campaign?.map(campaign => campaign._id));

                // Filter out duplicates from the new campaign list
                const newCampaigns = FinalCampaignListResponse?.data?.campaign.filter(campaign => !existingCampaignIDs.has(campaign._id)) || [];
    
                // Combine existing campaigns with new campaigns
                if(viewMore){
                    this.campaignList = {...FinalCampaignListResponse?.data,
                        campaign: [...(this.campaignList?.campaign || []), ...newCampaigns],
                    };
                }else{
                    this.campaignList = {
                        campaign: [...(this.campaignList?.campaign || []), ...newCampaigns],...FinalCampaignListResponse?.data
                    };
                }

                setCampaignListFromStore(true)
                if (loader !== undefined) {
                    loader(false)
                }
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
                loader(false)
            }
            if (loader !== undefined) {
                loader(false)
            }
        }
    }

    //  Delete Campaign
    async DeleteCampaignList({ camID, setCampaignListFromStore, loader, limit , page }) {
        loader(true)
        try {
            const campaignListResponse = await apiService.delete(`/user/campaign/${camID}`)
            const FinalCampaignListResponse = await campaignListResponse.data
            if (FinalCampaignListResponse.success === true) {
                loader(false)
                this.getCampaignList({ setCampaignListFromStore,loader,limit , page })
                // setCampaignListFromStore(true)
                toast.success(FinalCampaignListResponse.message)    
            }
            // if (FinalCampaignListResponse.success === true) {
            //     this.campaignList = FinalCampaignListResponse.data
            //     setCampaignListFromStore(true)
            //     if (loader !== undefined) {
            //         loader(false)
            //     }
            // }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
            if (loader !== undefined) {
                loader(false)
            }
        }
    }

    //campaign Name Rename With This Api
    async UpdateCampaignName({ finalData : data, campaignID, loader, setCampaignNamePopup, setCampaignListFromStore, limit , page }) {
        try {
            let dataForSend 
            this.encryptMode ? dataForSend.data = encryptData(data) : dataForSend = data
            const updateNameResponse = await apiService.patch(`/user/campaign/rename/${campaignID}`, { ...dataForSend })
            const FinalUpdateNameResponse = await updateNameResponse.data
            if (FinalUpdateNameResponse.success === true) {
                toast.success(FinalUpdateNameResponse.message)
                loader(false)
                setCampaignNamePopup(false)
                const updatedCampaignList = this.campaignList.campaign.map(campaign => {
                    if (campaign._id === campaignID) { 
                        return { ...campaign, campaignName: data.campaignName }; 
                    }else{
                        return campaign; 
                    }
                });
    
                this.campaignList = {
                    ...this.campaignList,
                    campaign: updatedCampaignList
                };
                setCampaignListFromStore(true)
                // this.getCampaignList({ setCampaignListFromStore, limit , page })
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
                loader(false)
            }
        }
    }

    // get Single Campaign Data
    async getSingleCampaignData({ campaignID }) {
        try {
            const singleCampaignDataResponse = await apiService.get(`/user/campaign/${campaignID}`);
            const FinalUpdateNameResponse = this.encryptMode ? decryptData(singleCampaignDataResponse.data) : singleCampaignDataResponse.data;
            if (FinalUpdateNameResponse.success === true) {
                this.singleCampaignData = FinalUpdateNameResponse.data;
                console.log(this.singleCampaignData)
                return FinalUpdateNameResponse.data;
            }
        } catch (error) {
            // Improved error handling
                const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error);
                if (!isAuthFailed) {
                    toast.error(error.response.data.error);
            } else {
                // Handle network errors or other unexpected issues
                toast.error("An unexpected error occurred.");
            }
        }
    }

    //get all excel list for single Campaign
    async getExcelListOfSingleCampaign({ campaignID, setGetExcelListFromStore }) {
        try {
            const response = await apiService.get(`/user/datafile/${campaignID}`)
            const FinalResponse = await response.data
            if (FinalResponse.success === true) {
                this.ExcelListForSingleCampaign = FinalResponse.data
                setGetExcelListFromStore(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
            }
        }
    }

    //upload excel 
    async uploadExcel({campaignID , formdata, setApiCallLoader, setAddExcelModel, setGetExcelListFromStore }) {
        try {
            const response = await apiService.post(`/user/datafile/${campaignID}`, formdata, { headers: { "Content-Type": "multipart/form-data" } })
            const FinalResponse = await response.data
            if (FinalResponse.success === true) {
                this.getExcelListOfSingleCampaign({ campaignID: campaignID, setGetExcelListFromStore })
                toast.success(FinalResponse.message)
                setApiCallLoader(false)
                setAddExcelModel(false)
                setGetExcelListFromStore(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
            setApiCallLoader(false)
        }
    }

    //upload Video 
    async uploadVideo({ campaignID , formdata, setApiCallLoader, volume, setReplaceModel }) {
        try {
            const response = await apiService.patch(`/user/campaign/video/${campaignID}`, formdata, { headers: { "Content-Type": "multipart/form-data" } })
            const FinalResponse = await response.data
            if (FinalResponse.success === true) {
                this.editorElements.map((element) => {
                    if (element.type === "video") {
                        console.log('come in this condition')
                        this.removeElement(element)
                    }
                });
                console.log('come in this first')
                toast.success(FinalResponse.message)
                this.addVideoResource(`${process.env.REACT_APP_MEDIA_URL + FinalResponse.data.videoUrl}`);
                const newF = () => { };
                this.addVideo(`${process.env.REACT_APP_MEDIA_URL + FinalResponse.data.videoUrl}`, volume, '', '', newF);
                // this.getSingleCampaignData({ campaignID: formdata.get("campaignId"), userID: formdata.get("userId"), })
                setTimeout(() => {
                    setApiCallLoader(false)
                    setReplaceModel(false);
                }, 2500)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
            setApiCallLoader(false)
        }
    }

    //upload Video 
    // async JsonUpdate({ formdata, data, setApiCallLoader, volume, setReplaceModel, isCallFromVideoReplace }) {
    //     try {
    //         const response = await apiService.post(`/user//campaign`, data)
    //         const FinalResponse = await response.data
    //         if (FinalResponse.success === true) {
    //             if (isCallFromVideoReplace) {
    //                 document.getElementsByClassName('canvas-img')[0].src = ""
    //                 this.uploadVideo({ formdata, setApiCallLoader, volume, setReplaceModel })
    //             } else {
    //                 toast.success(FinalResponse.message)
    //                 setApiCallLoader(false)
    //             }
    //         }
    //     } catch (error) {
    //         const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
    //         if (!isAuthFailed) {
    //             toast.error(error.response.data.message)
    //         }
    //     }
    // }
    async JsonUpdate({ campaignId , data, setApiCallLoader }) {
        try {
            const response = await apiService.patch(`/user/campaign/${campaignId}`, data)
            const FinalResponse = await response.data
            if (FinalResponse.success === true) {
            toast.success(FinalResponse.message)
            setApiCallLoader(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
        }
    }
    
    //Add textToSpeech 
    async textToSpeech({ campaignID , finaldata, setApiCallLoader, setEngine }) {
        try {
            const response = await apiService.post(`/user/campaign/tts/${campaignID}`, finaldata)
            const FinalResponse = await response.data
            if (FinalResponse.success === true) {
                toast.success(FinalResponse.message)

                const editorElementData = {}
                if (finaldata.engine === 'hyperclip') {
                    editorElementData.engine = 'hyperclip'
                    editorElementData.language = finaldata.language
                    editorElementData.dataset = finaldata.dataset
                    editorElementData.speaker = finaldata.speaker || 0
                    editorElementData.text = finaldata.text
                    editorElementData.isDefault = finaldata.isDefault
                } else if (finaldata.engine === 'openai') {
                    editorElementData.engine = 'openai'
                    editorElementData.text = finaldata.text
                    editorElementData.voice = finaldata.voice
                    editorElementData.speed = finaldata.speed
                    editorElementData.language = finaldata.language
                }


                this.addtexttospeech(process.env.REACT_APP_MEDIA_URL + FinalResponse.data.file, finaldata.text, undefined, undefined, editorElementData)
                setEngine("")
                setApiCallLoader(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.data.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
            setApiCallLoader(false)
        }
    }

    //export Excel
    async exportExcel({ finaldata, setGetExcelListFromStore, setApiCallLoader, setExcelForExportModal, setConformExcelExportModal }) {
        try {
            const response = await apiService.post(`/user/datafile/export`, finaldata);
            const FinalResponse = await response?.data;
            console.log(FinalResponse?.data)
            if (FinalResponse) {
                setExcelForExportModal();
                setConformExcelExportModal(false)
                this.getExcelListOfSingleCampaign({ campaignID: finaldata.campaignID,  setGetExcelListFromStore })
                const bufferData = FinalResponse?.data?.buffer?.data
                const blob = new Blob([new Uint8Array(bufferData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a link element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = FinalResponse?.data?.fileName; // You can name the file here

                // Append the link to the document body and click it
                document.body.appendChild(link);
                link.click();

                // Clean up by removing the link after download is triggered
                document.body.removeChild(link);
                setGetExcelListFromStore(true);
                setApiCallLoader(false);
                

            }
        } catch (error) {
            setApiCallLoader(false)
            const isAuthFailed = errorStatusHandler(error.response?.data?.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
        }
    }

    //Delete Excel
    async DeleteExcel({ dataFileID, campaignID, setGetExcelListFromStore, setApiCallLoader, setDeleteItem }) {
        try {
            const response = await apiService.delete(`/user/datafile/${campaignID}`, {
                data: { dataFileID } // This ensures the excelId is sent in the body
            })
            const FinalResponse = await response.data
            if (FinalResponse.success === true) {
                toast.success(FinalResponse.message)
                this.getExcelListOfSingleCampaign({ campaignID: campaignID, setGetExcelListFromStore })
                setApiCallLoader(false)
                setDeleteItem("")
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response?.data?.statusCode, error.response.data.error)
            if (!isAuthFailed) {
                toast.error(error.response.data.error)
            }
            setApiCallLoader(false)
        }
    }

    setVideoURL(url) {
        this.videoURL = url
    }

    setCurrentTimeInMs(time) {
        this.currentKeyFrame = Math.floor(time / 1000 * this.fps);
    }

    addVideoResource(videoUrl) {
        this.videos = [videoUrl];
    }

    // Add this method to handle adding video configurations
    addVideoConfig(videoConfig) {
        this.videoConfigs.push(videoConfig);
    }

    createVideoObject(videoElement, element) {
        return new fabric.CoverVideo(videoElement, {
            name: element.id,
            left: element.placement.x,
            top: element.placement.y,
            width: videoElement.width,
            height: videoElement.height,
            scaleX: element.placement.scaleX,
            scaleY: element.placement.scaleY,
            angle: element.placement.rotation,
            objectCaching: false,
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            hasControls: false,
        });
    }

    handleTimeLineZoom(value) {
        this.timeLineZoom = value
    }

    // Add this method to handle adding text configurations
    addTextConfig(textConfig) {

        const newConFig = [textConfig]
        this.textConfigs = newConFig
    }
    // Assuming setSelectedMenuOption is a method inside a class
    // setSelectedMenuOption(selectedMenuOption: MenuOption) {
    //   const canvas = this.canvas;
    //   if (!canvas) {
    //     console.error("Canvas element not found.");
    //     return;
    //   }
    //   this.selectedMenuOption = selectedMenuOption;
    // }

    setSelectedMenuOption(selectedMenuOption) {
        this.selectedMenuOption = selectedMenuOption;
    }

    setCanvas(canvas) {
        this.canvas = canvas;
        if (canvas) {
            canvas.backgroundColor = this.backgroundColor;
        }
    }

    clearCanvas() {
        this.canvas = null;
        this.editorElements = [];
        this.selectedElement = null;
        // this.videoDuration = 0;
        this.videoSizePercentage = 0;
        this.setCurrentTimeInMs(0);
        this.setPlaying(false);
    }

    addElement(element) {
        this.editorElements.push(element);
        this.saveHistory();
        this.renderElements();
    }

    removeElement(element) {
        const index = this.editorElements.indexOf(element);
        if (index !== -1) {
            this.editorElements.splice(index, 1);
            this.saveHistory();
            this.renderElements();
        }
    }

    saveHistory() {
        const clonedElements = this.editorElements.map(element => ({ ...element }));
        this.history.splice(this.historyIndex + 1);
        this.history.push(clonedElements);
        this.historyIndex++;
    }

    undo() {
        if (this.historyIndex > 0) {
            this.historyIndex--;
            this.editorElements = this.history[this.historyIndex].slice();
            this.renderElements();
        }
    }

    redo() {
        if (this.historyIndex < this.history.length - 1) {
            this.historyIndex++;
            this.editorElements = this.history[this.historyIndex].slice();
            this.renderElements();
        }
    }

    renderElements() {
        if (!this.canvas) {
            return;
        }
        this.canvas.clear();
    }

    setBackgroundColor(backgroundColor) {
        if (this.canvas) {
            this.canvas.backgroundColor = backgroundColor;
            // this.saveHistory(); 
        }
    }

    updateEffect(id, effect) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorVideoElement(element) || isEditorImageElement(element)) {
            element.properties.effect = effect;
        }
        this.refreshElements();
    }

    setVideos(videos) {
        this.videos = videos;
    }

    updateVolumn(id, volume) {
        const updatedVolume = document.getElementById(`video-${id}`);
        if (updatedVolume) {
            this.volumeOfVideo = volume
            updatedVolume.volume = volume / 100;
        } else {
        }
    }

    updateBtnTextColor(id, textColor) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.textColor = textColor
        }
        this.refreshElements();
    }

    updateText(id, text) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];

        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.text = text;
        }
        this.refreshElements();
    }

    handleFixedWidth(id, width) {

        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        element.properties.fixedWidth = width
        this.refreshElements()
        this.selectedElement = null;
        this.selectedElement = element

    }

    handleTextFixedWidth(id, width) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        element.properties.fixedWidth = width
        this.refreshElements()
    }

    handlePaddingForText(id, from, padding) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (from === "x") {
            element.properties.paddingX = Number(padding)
        } else if (from === 'y') {
            element.properties.paddingY = Number(padding)
        }
        this.refreshElements()
    }

    handleScaleForText(id, from, scale) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (from === "x") {
            element.placement.scaleX = Number(scale)
        } else if (from === 'y') {
            element.placement.scaleY = Number(scale)
        }
        this.refreshElements()
    }

    handleTextPosition(id, from, scale) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (from === "x") {
            element.placement.x = Number(scale)
        } else if (from === 'y') {
            element.placement.y = Number(scale)
        }
        this.refreshElements()
    }

    handleAlign(id, value) {

        const index = this.editorElements.findIndex((element) => element.id === id)
        const element = this.editorElements[index]
        element.placement.x = value
        this.refreshElements()
    }

    handleTextAling(id, value) {
        const index = this.editorElements.findIndex((element) => element.id === id)
        const element = this.editorElements[index]
        element.properties.textAlign = value
        this.refreshElements()
    }




    handleangle(id) {
        const index = this.editorElements.findIndex((element) => element.id === id)
        const element = this.editorElements[index]
        // element.properties.angle		= 90
        this.refreshElements()
    }

    updateFontSize(id, fontSizes) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            // const hhh = JSON.parse(JSON.stringify(textObject))

            const hhh = JSON.parse(JSON.stringify(element.fabricObject))

            // element.fabricObject?.set("fontSize", fontSizes)


            // const finalHeight = hhh.objects.find(item => item.type === 'i-text').height
            // const finalWidth = hhh.objects.find(item => item.type === 'i-text').width

            element.properties.fontSize = fontSizes
            // element.properties.width = fin
            // element.placement.width = 45.49
            // element.placement.height = finalHeight
        }
        this.refreshElements();
    }

    updateFontFamily(id, fontFamily) {
        console.log(id, fontFamily)
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.fontFamily = fontFamily
        }
        this.refreshElements();
    }

    updateFontWeight(id, fontWeight) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.fontWeight = fontWeight
        }
        this.refreshElements();
    }

    updateBackgroundColor(id, backgroundColor) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.backgroundColor = backgroundColor
        }
        this.refreshElements();
    }

    updateButtonLink(id, linkUrl) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorButtonElement(element)) {
            element.properties.linkUrl = linkUrl
        }
        this.refreshElements();
    }

    updateWidth(id, width) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.paddingX = width
        }
        this.refreshElements();
    }

    updateHeight(id, height) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.paddingY = height
        }
        this.refreshElements();
    }

    updateUnderLine(id, underline) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.underline = underline
        }
        this.refreshElements();
    }

    updateItalic(id, italic) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.fontStyle = italic
        }
        this.refreshElements();
    }

    updateTextAlign(id, textAlign) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorButtonElement(element)) {
            element.properties.textAlign = textAlign
        }
        this.refreshElements();
    }

    updateOutline(id, outline) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element)) {
            element.properties.outline = outline
        }
        this.refreshElements();
    }

    updateOutlineWidth(id, outline) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element)) {
            element.properties.outlineWidth = outline
        }
        this.refreshElements();
    }

    updateBorder(id, border) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorButtonElement(element)) {
            element.properties.strokeWidth = border
        } else if (isEditorTextElement(element)) {
            element.properties.strokeWidth = border
        }
        this.refreshElements();
    }

    updateBorderColor(id, borderColor) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorButtonElement(element)) {
            element.properties.stroke = borderColor
        } else if (isEditorTextElement(element)) {
            element.properties.stroke = borderColor
        }
        this.refreshElements();
    }

    updateBorderRadius(id, borderRadius) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.borderRadius = borderRadius
        }
        this.refreshElements();
    }

    updateCharSpacing(id, charSpacing) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.charSpacing = charSpacing
        }
        this.refreshElements();
    }

    updateLineHeight(id, lineHeight) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.lineHeight = lineHeight
        }
        this.refreshElements();
    }

    updateUppercase(id) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            let text = element.properties.text;
            if (text === text.toUpperCase()) {
            } else {
                element.properties.text = text?.toUpperCase();
            }
        }
        this.refreshElements();
    }

    updateLowercase(id) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            let text = element.properties.text;

            if (text === text.toLowerCase()) {
            } else {
                element.properties.text = text?.toLowerCase();
            }
        }
        this.refreshElements();
    }

    updateTxtOpacity(id, txtOpacity) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.txtOpacity = txtOpacity
        }
        this.refreshElements();
    }

    updateBtnOpacity(id, btnOpacity) {
        const index = this.editorElements.findIndex((element) => element.id === id);
        const element = this.editorElements[index];
        if (isEditorTextElement(element) || isEditorButtonElement(element)) {
            element.properties.btnOpacity = btnOpacity
        }
        this.refreshElements();
    }

    updateStartEndTime(id, from, time) {
        const element = this.editorElements.find((element) => element.id === id);


        if (from === "start") {
            element.timeFrame.start = time;
        } else if (from === 'end') {
            element.timeFrame.end = time;
        }
    }

    addAudioResource(audio) {
        this.audios = [...this.audios, audio];
    }

    addImageResource(image) {
        this.images = [...this.images, image];
    }

    addAnimation(animation) {
        this.animations = [...this.animations, animation];


        this.refreshAnimations();

        // Retrieve textConfigs from local storage
        const storedTextConfigsString = localStorage.getItem("textConfigs");

        if (!storedTextConfigsString) return;

        const textConfigs = JSON.parse(storedTextConfigsString);

        // Find the last added text configuration and update its animationType
        const lastAddedTextConfigIndex = textConfigs.length - 1;
        if (lastAddedTextConfigIndex >= 0) {
            textConfigs[lastAddedTextConfigIndex].animationType = animation.type;
        }

        // Save the updated textConfigs back to local storage
        localStorage.setItem("textConfigs", JSON.stringify(textConfigs));
        this.addTextConfig(textConfigs[lastAddedTextConfigIndex]);
    }

    updateAnimation(id, animation) {
        const index = this.animations.findIndex((a) => a.id === id);
        this.animations[index] = animation;
        this.refreshAnimations();
    }

    refreshAnimations() {
        anime?.remove(this.animationTimeLine);
        this.animationTimeLine = anime?.timeline({
            duration: this.maxTime,
            autoplay: false,
        });

        for (let i = 0; i < this.animations.length; i++) {
            const animation = this.animations[i];
            const editorElement = this.editorElements.find((element) => element.id === animation.targetId);
            const fabricObject = editorElement?.fabricObject;
            if (!editorElement || !fabricObject) {
                continue;
            }

            fabricObject.clipPath = undefined;

            switch (animation.type) {
                case "fadeIn": {
                    this.animationTimeLine.add({
                        opacity: [0, 1],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.start);
                    break;
                }

                case "fadeOut": {
                    this.animationTimeLine.add({
                        opacity: [1, 0],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, (editorElement.timeFrame.end - animation.duration));
                    break
                }
                case "slideIn": {
                    const direction = animation.properties.direction;
                    const targetPosition = {
                        left: (this.canvas.width * editorElement.placement.x) / 100,
                        top: (this.canvas.height * editorElement.placement.y) / 100,
                    }
                    const startPosition = {
                        left: (direction === "left" ? Number((0 - (editorElement.fabricObject.width * editorElement.placement.scaleX)))
                            : direction === "right" ? this.canvas?.width
                                : (this.canvas.width * editorElement.placement.x) / 100),
                        top: (direction === "top" ? Number(0 - (editorElement.fabricObject.height * editorElement.placement.scaleY))
                            : direction === "bottom" ? this.canvas?.height
                                : ((this.canvas.height * editorElement.placement.y) / 100)),
                    }
                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle)
                    }
                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],
                        top: [startPosition.top, targetPosition.top],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.start);
                    break
                }

                case "slideOut": {
                    const direction = animation.properties.direction;
                    const startPosition = {
                        left: (this.canvas.width * editorElement.placement.x) / 100,
                        top: (this.canvas.height * editorElement.placement.y) / 100,
                    }

                    const targetPosition = {
                        left: (direction === "left" ? Number((0 - (editorElement.fabricObject.width * editorElement.placement.scaleX)))
                            : direction === "right" ? this.canvas?.width
                                : (this.canvas.width * editorElement.placement.x) / 100),
                        top: (direction === "top" ? Number(0 - (editorElement.fabricObject.height * editorElement.placement.scaleY))
                            : direction === "bottom" ? this.canvas?.height
                                : ((this.canvas.height * editorElement.placement.y) / 100)),
                        top: (direction === "top" ? Number(0 - (editorElement.fabricObject.height * editorElement.placement.scaleY))
                        : direction === "bottom" ? this.canvas?.height
                            : ((this.canvas.height * editorElement.placement.y) / 100)),
                    }

                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle)
                    }

                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],
                        top: [startPosition.top, targetPosition.top],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.end - animation.duration);
                    break
                }

                case "breathe": {
                    const itsSlideInAnimation = this.animations.find((a) => a.targetId === animation.targetId && (a.type === "slideIn"));
                    const itsSlideOutAnimation = this.animations.find((a) => a.targetId === animation.targetId && (a.type === "slideOut"));
                    const timeEndOfSlideIn = itsSlideInAnimation ? editorElement.timeFrame.start + itsSlideInAnimation.duration : editorElement.timeFrame.start;
                    const timeStartOfSlideOut = itsSlideOutAnimation ? editorElement.timeFrame.end - itsSlideOutAnimation.duration : editorElement.timeFrame.end;
                    if (timeEndOfSlideIn > timeStartOfSlideOut) {
                        continue;
                    }

                    const duration = timeStartOfSlideOut - timeEndOfSlideIn;
                    const easeFactor = 4;
                    const suitableTimeForHeartbeat = 1000 * 60 / 72 * easeFactor
                    const upScale = 1.05;
                    const currentScaleX = fabricObject.scaleX ?? 1;
                    const currentScaleY = fabricObject.scaleY ?? 1;
                    const finalScaleX = currentScaleX * upScale;
                    const finalScaleY = currentScaleY * upScale;
                    const totalHeartbeats = Math.floor(duration / suitableTimeForHeartbeat);
                    if (totalHeartbeats < 1) {
                        continue;
                    }

                    const keyframes = [];
                    for (let i = 0; i < totalHeartbeats; i++) {
                        keyframes.push({ scaleX: finalScaleX, scaleY: finalScaleY });
                        keyframes.push({ scaleX: currentScaleX, scaleY: currentScaleY });
                    }

                    this.animationTimeLine.add({
                        duration: animation.duration,
                        targets: fabricObject,
                        keyframes,
                        easing: 'linear',
                        loop: true
                    }, timeEndOfSlideIn);
                    break
                }

                case "zoomIn": {
                    this.animationTimeLine.add({
                        scaleX: [0, 1],
                        scaleY: [0, 1],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.start);
                    break;
                }

                case "zoomOut": {
                    this.animationTimeLine.add({
                        scaleX: [1, 0],
                        scaleY: [1, 0],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'linear',
                    }, editorElement.timeFrame.end - animation.duration);
                    break
                }

                case "bounceIn": {
                    this.animationTimeLine.add({
                        scaleY: [0, 1],
                        scaleX: [0, 1],
                        duration: animation.duration,
                        easing: 'easeInBounce',
                        targets: fabricObject,
                    }, editorElement.timeFrame.start);
                    break;
                }

                case "bounceOut": {
                    this.animationTimeLine.add({
                        scaleX: [1, 0.7, 0],
                        scaleY: [1, 0.7, 0],
                        duration: animation.duration,
                        easing: 'easeOutBounce',
                        targets: fabricObject,
                        update: (anim) => {
                            if (fabricObject.scaleX === 0 || fabricObject.scaleY === 0) {
                                fabricObject.scaleX = 1;
                                fabricObject.scaleY = 1;
                            }
                        }
                    }, editorElement.timeFrame.end - animation.duration);


                    break;
                }

                case "lightspeedOut": {
                    const direction = animation.properties.direction;
                    const startPosition = {
                        left: editorElement.placement.x,
                        top: editorElement.placement.y,
                    }

                    const targetPosition = {
                        left: (direction === "left" ? - editorElement.placement.width : direction === "right" ? this.canvas?.width : editorElement.placement.x),
                    }
                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle)
                    }
                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'easeOutBack',
                    }, editorElement.timeFrame.end - animation.duration);

                    break
                }

                case "lightspeedIn": {
                    const direction = animation.properties.direction;
                    const targetPosition = {
                        left: editorElement.placement.x,
                        top: editorElement.placement.y,
                    }
                    const startPosition = {
                        left: (direction === "left" ? - editorElement.placement.width : direction === "right" ? this.canvas?.width : editorElement.placement.x),
                    }
                    if (animation.properties.useClipPath) {
                        const clipRectangle = FabricUitls.getClipMaskRect(editorElement, 50);
                        fabricObject.set('clipPath', clipRectangle)
                    }
                    this.animationTimeLine.add({
                        left: [startPosition.left, targetPosition.left],
                        duration: animation.duration,
                        targets: fabricObject,
                        easing: 'easeOutBack',
                    }, editorElement.timeFrame.start);
                    break
                }

                case "typewriter": {
                    if (fabricObject instanceof fabric.Text) {
                        const textValue = fabricObject.text;
                        if (textValue !== undefined) {
                            const durationPerCharacter = animation.duration / textValue.length;
                            let currentIndex = 0;

                            const animateNextCharacter = () => {
                                if (currentIndex < textValue.length) {
                                    const newText = textValue.slice(0, currentIndex + 1);
                                    fabricObject.set('text', newText);
                                    fabricObject.setCoords();
                                    this.canvas?.renderAll();
                                    currentIndex++;
                                    if (currentIndex < textValue.length) {
                                        setTimeout(animateNextCharacter, durationPerCharacter);
                                    } else {
                                        this.animationTimeLine.restart();
                                    }
                                }
                            };
                            // Function to start the animation
                            const startTypewriterAnimation = () => {
                                currentIndex = 0; // Reset currentIndex each time animation starts
                                animateNextCharacter();
                            };
                            // Clear or restart the animation timeline before starting the animation
                            this.animationTimeLine.restart();

                            this.animationTimeLine.add({
                                begin: startTypewriterAnimation,
                                duration: animation.duration
                            }, editorElement.timeFrame.start);

                        }
                    }
                    break;
                }
            }
        }
    }

    removeAnimation(id) {
        this.animations = this.animations.filter(
            (animation) => animation.id !== id
        );

        this.refreshAnimations();
        this.refreshElements();
    }

    setSelectedElement(selectedElement) {
        this.selectedElement = selectedElement;
        if (this.canvas) {
            if (selectedElement?.fabricObject)
                this.canvas.setActiveObject(selectedElement.fabricObject);
            else
                this.canvas.discardActiveObject();
        }
    }

    updateSelectedElement() {
        this.selectedElement = this.editorElements.find((element) => element.id === this.selectedElement?.id) ?? null;
    }

    setEditorElements(editorElements) {
        this.editorElements = editorElements;
        this.updateSelectedElement();
        this.refreshElements();
        this.refreshAnimations();
    }

    updateEditorElement(editorElement) {
        this.setEditorElements(this.editorElements.map((element) =>
            element.id === editorElement.id ? editorElement : element
        ));
    }

    updateEditorElementTimeFrame(editorElement, timeFrame) {
        if (timeFrame.start !== undefined && timeFrame.start < 0) {
            timeFrame.start = 0;
        }
        if (timeFrame.end !== undefined) {
            if (timeFrame.end > this.maxTime) {
                timeFrame.end = this.maxTime;
            }
            if (timeFrame.end > this.audioDurationMs) {
                this.audioDurationMs = timeFrame.end;
            }
        }

        const newEditorElement = {
            ...editorElement,
            timeFrame: {
                ...editorElement.timeFrame,
                ...timeFrame,
            }
        };

        this.updateVideoElements();
        this.updateAudioElements();
        this.updateEditorElement(newEditorElement);
        this.refreshAnimations();
    }

    addEditorElement(editorElement) {
        this.setEditorElements([...this.editorElements, editorElement]);
        this.refreshElements();
        setTimeout(() => {
            this.setSelectedElement(this.editorElements[this.editorElements.length - 1]);
        }, 100)
    }

    removeEditorElement(id) {
        this.setEditorElements(this.editorElements.filter(
            (editorElement) => editorElement.id !== id
        ));

        const newData = this.setEditorElements(this.editorElements.filter(
            (editorElement) => editorElement.id !== id
        ))
        this.refreshElements();
    }

    setMaxTime(maxTime) {
        this.maxTime = maxTime;
    }

    setPlaying(playing) {
        this.playing = playing;
        this.updateVideoElements();
        this.updateAudioElements();
        const audioElement = document.getElementById('audioPlayer');
        this.updateTextToSpeechElement();
        if (playing) {
            this.startedTime = Date.now();
            this.startedTimePlay = this.currentTimeInMs;
            requestAnimationFrame(() => {
                this.playFrames();
            });
            // if (audioElement) {
            //     audioElement.play();
            // }
            this.editorElements.filter(
                (element) =>
                    element.type === "video"
            )
                .forEach((element) => {
                    const video = document.getElementById(element.properties.elementId);
                    if (isHtmlVideoElement(video)) {
                        if (this.playing) {
                            const videoTime = (this.currentTimeInMs - element.timeFrame.start) / 1000;
                            video.currentTime = videoTime;
                            video.play();
                        } else {
                            // video.currentTime = (this.currentTimeInMs - element.timeFrame.start);
                            video.pause();
                        }
                    }
                })
        } else {
            // if (audioElement) {
            //     audioElement.pause();
            // }
            this.editorElements.filter(
                (element) =>
                    element.type === "video"
            )
                .forEach((element) => {
                    const video = document.getElementById(element.properties.elementId);
                    if (isHtmlVideoElement(video)) {
                        if (this.playing) {
                            const videoTime = (this.currentTimeInMs - element.timeFrame.start) / 1000;
                            video.currentTime = videoTime;
                            video.play();
                        } else {
                            // video.currentTime = (this.currentTimeInMs - element.timeFrame.start);
                            video.pause();
                        }
                    }
                })
        }
        this.editorElements.filter(
            (element) =>
                element.type === "video"
        )
            .forEach((element) => {
                const video = document.getElementById(element.properties.elementId);
                if (isHtmlVideoElement(video)) {
                    if (this.playing) {
                        const videoTime = (this.currentTimeInMs - element.timeFrame.start) / 1000;
                        video.currentTime = videoTime;
                        video.play();
                    } else {
                        // video.currentTime = (this.currentTimeInMs - element.timeFrame.start);
                        video.pause();
                    }
                }
            })
    }

    startedTime = 1;


    startedTimePlay = 0;

    playFrames() {
        if (!this.playing) {
            return;
        }

        const elapsedTime = Date.now() - this.startedTime;
        const newTime = this.startedTimePlay + elapsedTime;
        this.updateTimeTo(newTime);
        if (newTime > this.maxTime) {
            this.currentKeyFrame = 0;
            this.setPlaying(false);
        } else {
            requestAnimationFrame(() => {
                this.playFrames();
            });
        }
    }

    // updateTimeTo(newTime) {
    // 	this.setCurrentTimeInMs(newTime);
    // 	this.animationTimeLine.seek(newTime);
    // 	if (this.canvas) {
    // 		this.canvas.backgroundColor = this.backgroundColor;
    // 	}

    // 	this.editorElements.forEach(
    // 		e => {

    // 			if (!e.fabricObject) return;
    // 			const isInside = e.timeFrame.start <= newTime && newTime <= e.timeFrame.end;

    // 			e.fabricObject.visible = isInside;
    // 			if(e.type === 'audio'){
    // 				const audio = document.getElementById(e.properties.elementId);
    // 				if(this.currentTimeInMs >= e.timeFrame.start && this.currentTimeInMs < e.timeFrame.end){
    // 					audio.play()
    // 				}
    // 				// else{
    // 				// 	audio?.pause()	
    // 				// }
    // 			}
    // 		}
    // 	)
    // }

    updateTimeTo(newTime) {
        this.setCurrentTimeInMs(newTime);
        this.animationTimeLine.seek(newTime);
        if (this.canvas) {
            this.canvas.backgroundColor = this.backgroundColor;
        }

        this.editorElements.forEach(e => {
            if (!e.fabricObject) return;
            const isInside = e.timeFrame.start <= newTime && newTime <= e.timeFrame.end;
            e.fabricObject.visible = isInside;
            if (e.type === 'audio') {
                const audio = document.getElementById(e.properties.elementId);
                if (audio) {
                    if (this.playing && (this.currentTimeInMs >= e.timeFrame.start) && (this.currentTimeInMs < e.timeFrame.end)) {
                        if (audio.paused) {
                            audio.play().catch((error) => {
                                console.error('Failed to play audio:', error);
                            });
                        }
                    } else {
                        if (!audio.paused) {
                            audio.pause();
                        }
                    }
                }
            }
        });
    }

    handleSeek(seek) {
        if (this.playing) {
            this.setPlaying(false);
        }
        this.updateTimeTo(seek);
        this.updateVideoElements();
        this.updateAudioElements();
    }

    addVideo(file, volume, properties, timeFrame, afterLoadFunction) {
        var videoElement = document.createElement('video');
        const id = getUid();
        videoElement.addEventListener('loadedmetadata', () => {
            this.videoDuration = videoElement.duration;
            const videoVolume = videoElement.volume;
            const videoConfig = {
                url: file,
                duration: this.videoDuration,
                volume: videoVolume,
            };

            // Push videoConfig to videoConfigs array
            this.videoConfigs = [videoConfig];
            // Store videoConfig in local storage
            // localStorage.setItem(`videoConfig-${id}`, JSON.stringify(videoConfig));

            this.addEditorElement({
                id,
                isVisible: true,
                name: `Media(video)`,
                type: "video",
                placement: {
                    x: properties?.x || 0,
                    y: properties?.y || 0,
                    width: properties?.width || 800,
                    height: properties?.height || 500,
                    rotation: properties?.rotation || 0,
                    scaleX: properties?.scaleX || 1,
                    scaleY: properties?.scaleY || 1,
                },
                timeFrame: {
                    start: timeFrame?.start || 0,
                    end: timeFrame?.end || this.videoDuration * 1000,
                    UpdateStart: timeFrame?.UpdateStart || 0,
                    UpdateEnd: timeFrame?.UpdateEnd || 0
                },
                properties: {
                    elementId: `video-${id}`,
                    src: file,
                    effect: {
                        type: "none",
                    },
                    volume: volume,
                    width: 0,
                    height: 0,
                },
            });
            this.maxTime = this.videoDuration * 1000
            afterLoadFunction()
        });
        videoElement.src = file;
    }

    isVisible(id, value) {
        const index = this.editorElements.findIndex((element) => element.id === id)
        const element = this.editorElements[index]
        if (element.timeFrame.start < this.maxTime) {
            element.timeFrame.UpdateStart = element.timeFrame.start
            element.timeFrame.UpdateEnd = element.timeFrame.end
            element.timeFrame.start = this.maxTime
        } else {
            element.timeFrame.start = element.timeFrame.UpdateStart
            element.timeFrame.end = element.timeFrame.UpdateEnd
            element.timeFrame.UpdateEnd = 0
            element.timeFrame.UpdateStart = 0
        }
        this.refreshElements()
    }

    addImage(index) {
        const imageElement = document.getElementById(`image-${index}`)
        if (!isHtmlImageElement(imageElement)) {
            return;
        }

        const aspectRatio = imageElement.naturalWidth / imageElement.naturalHeight;
        const id = getUid();
        this.addEditorElement(
            {
                id,
                isVisible: true,
                name: `Media(image) ${index + 1}`,
                type: "image",
                placement: {
                    x: 0,
                    y: 0,
                    width: 100 * aspectRatio,
                    height: 100,
                    rotation: 0,
                    scaleX: 1,
                    scaleY: 1,
                },
                timeFrame: {
                    start: 0,
                    end: this.maxTime,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    elementId: `image-${id}`,
                    src: imageElement.src,
                    effect: {
                        type: "none",
                    }
                },
            },
        );
    }

    addAudio(index) {
        const audioElement = document.getElementById(`audio-${index}`)
        if (!isHtmlAudioElement(audioElement)) {
            return;
        }
        const audioDurationMs = audioElement.duration * 1000;

        const id = getUid();
        this.addEditorElement(
            {
                id,
                isVisible: true,
                name: `Media(audio) ${index + 1}`,
                type: "audio",
                placement: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                    rotation: 0,
                    scaleX: 1,
                    scaleY: 1,
                },
                timeFrame: {
                    start: 0,
                    end: audioDurationMs,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    elementId: `audio-${id}`,
                    src: audioElement.src,
                    text: ""
                }
            },
        );
    }

    addText(options) {
        let id;
        if (options.id) {
            id = options.id
        } else {
            id = getUid();
        }
        const index = this.editorElements.length;
        const canvas = this.canvas;
        if (!canvas) return;

        const text = new CustomRectText({
            left: options.x,
            top: options.y,
            text: options.text,
            fontSize: options.fontSize,
            fontFamily: options.fontFamily,
            fontWeight: options.fontWeight,
            fill: options.fill || options.textColor,
            selectable: true,
            backgroundColor: options.btnBg || options.backgroundColor,
            width: options.btnWidth || options.btnWidth,
            height: options.btnHeight || options.height,
            underline: options.underline,
            fontStyle: options.italic || options.fontStyle,
            textAlign: 'center',
            borderRadius: options.borderRadius,
            scaleX: 1,
            scaleY: 1,
            lineHeight: options.lineHeight,
            charSpacing: options.charSpacing,
            txtOpacity: options.txtOpacity || 0,
            btnOpacity: options.btnOpacity || 0,
            outline: options.outline,
            outlineWidth: options.outlineWidth,
            angle: options.angle,
            paddingX: options.paddingX,
            paddingY: options.paddingY,
            stroke: options.stroke,
            strokeWidth: options.strokeWidth || 0,
        }, canvas)

        canvas.add(text)

        // Add the text element to the editor
        this.addEditorElement(
            {
                id,
                isVisible: true,
                name: `Text ${index + 1}`,
                type: "text",
                placement: {
                    x: options.x || 0,
                    y: options.y || 0,
                    width: options.width || 100,
                    height: options.height,
                    rotation: options.rotation || 0,
                    scaleX: options.scaleX || 1,
                    scaleY: options.scaleY || 1,
                },
                timeFrame: {
                    start: options.start || 0,
                    end: options.end || this.maxTime,
                    UpdateStart: options.UpdateStart || 0,
                    UpdateEnd: options.UpdateEnd || 0
                },
                properties: {
                    // animationType: options.animationType,
                    text: options.text,
                    fontSize: options.fontSize,
                    fontWeight: options.fontWeight,
                    textColor: options.textColor,
                    backgroundColor: options.backgroundColor,
                    fontFamily: options.fontFamily,
                    // width: options.btnWidth || options.btnWidth,
                    // height: options.btnHeight || options.height,
                    underline: options.underline || false,
                    fontStyle: options.fontStyle,
                    textAlign: options.textAlign,
                    borderRadius: options.borderRadius,
                    lineHeight: options.lineHeight,
                    charSpacing: options.charSpacing,
                    txtOpacity: options.txtOpacity || 0,
                    btnOpacity: options.btnOpacity || 0,
                    outline: options.outline,
                    outlineWidth: options.outlineWidth,
                    angle: options.angle,
                    fixedWidth: options.fixedWidth || false,
                    paddingX: options.paddingX || 15,
                    paddingY: options.paddingY || 15,
                    stroke: options.stroke,
                    strokeWidth: options.strokeWidth || 0,
                },
            });

        this.saveHistory()
    }

    addButton(options) {


        const id = getUid();

        const index = this.editorElements.length;

        const canvas = this.canvas;
        if (!canvas) return;

        const button = new CustomRectButton({
            text: options.text,
            x: options.x || 0,
            y: options.y || 0,
            paddingX: options.paddingX,
            paddingY: options.paddingY,
            backgroundColor: options.backgroundColor,
            borderRadiusX: options.borderRadiusX,
            borderRadiusY: options.borderRadiusY,
            originX: options.originX,
            originY: options.originY,
            stroke: options.stroke,
            strokeWidth: options.strokeWidth || 0,
            fontSize: options.fontSize,
            textColor: options.textColor,
            txtOpacity: options.txtOpacity || 0,
            btnOpacity: options.btnOpacity || 0,
            fontFamily: options.fontFamily,
            linkUrl: options.linkUrl,
            underline: options.underline || false,
            charSpacing: options.charSpacing || 0,
            textAlign: options.textAlign,
        }, canvas)

        canvas.add(button);

        this.addEditorElement({
            id,
            isVisible: true,
            name: `Button${index + 1}`,
            type: "button",
            placement: {
                x: options?.x || 0,
                y: options?.y || 0,
                width: '',
                height: '',
                rotation: options?.rotation || 0,
                scaleX: options?.scaleX || 1,
                scaleY: options?.scaleY || 1,
            },
            timeFrame: {
                start: options.start || 0,
                end: options.end || this.maxTime,
                UpdateStart: 0,
                UpdateEnd: 0
            },
            properties: {
                text: options.text,
                fontSize: options.fontSize,
                fontWeight: options.fontWeight,
                textColor: options.textColor,
                backgroundColor: options.backgroundColor,
                fontFamily: options.fontFamily,
                linkUrl: options.linkUrl,
                width: "",
                height: "",
                underline: options.underline || false,
                fontStyle: options.fontStyle,
                textAlign: options.textAlign,
                strokeWidth: options.strokeWidth || 0,
                stroke: options.stroke,
                borderRadius: options.borderRadius,
                btnOpacity: options.btnOpacity || 0,
                txtOpacity: options.txtOpacity || 0,
                lineHeight: options.lineHeight || 1.12,
                charSpacing: options.charSpacing || 0,
                paddingX: options.paddingX || 15,
                paddingY: options.paddingY || 15,
                angle: options.angle || 0,
            },
        });
    }

    addtexttospeech(audioData, inputValue, placement, timeFrame, editorElementData) {

        const audioElement = document.createElement('audio');
        // audioElement.src = process.env.REACT_APP_MEDIA_URL + audioData;
        audioElement.src = audioData;
        // audioElement.src = `${audioData}`;

        audioElement.controls = true;
        audioElement.onloadedmetadata = () => {
            const audioDurationMs = audioElement.duration * 1000;

            if (isNaN(audioDurationMs)) {
                return;
            }
            // Update audioDurationMs dynamically
            this.audioDurationMs = audioDurationMs;

            const canvas = this.canvas;

            if (!canvas) {
                return;
            }

            const fabricAudio = new fabric.Textbox('Audio Element', {
                left: 100,
                top: 100,
                width: 200,
                height: 50,
                fill: 'transparent',
                hasControls: false,
                hasBorders: false,
                selectable: false,
                fontFamily: 'Arial',
                fontSize: 14,
                originX: 'left',
                originY: 'top',
            });

            canvas.add(fabricAudio);

            let id;  // Use let instead of const to allow reassignment

            // Check if the element already has an ID
            if (editorElementData && editorElementData && editorElementData.elementId) {
                id = editorElementData.elementId?.replace("audio-", ""); // Use the existing ID
            } else {
                id = `${getUid()}`; // Generate new ID if none exists
            }

            this.addEditorElement({
                id,
                fabricObject: fabricAudio,
                isVisible: true,
                name: `Media(audio)`,
                type: "audio",
                placement: {
                    x: placement?.x || 0,
                    y: placement?.y || 0,
                    width: placement?.width || 800,
                    height: placement?.height || 500,
                    rotation: placement?.rotation || 0,
                    scaleX: placement?.scaleX || 1,
                    scaleY: placement?.scaleY || 1,
                },
                timeFrame: {
                    start: timeFrame?.start || this.startedTime,
                    end: timeFrame?.end || this.startedTime + audioDurationMs,
                    UpdateStart: timeFrame?.UpdateStart || 0,
                    UpdateEnd: timeFrame?.UpdateEnd || 0
                },
                properties: {
                    elementId: `audio-${id}`,
                    src: audioElement.src,
                    text: inputValue,
                    ...editorElementData
                },
            });

        };
    }

    // int his when addtexttospeech
    updateVideoElements() {
        this.editorElements.filter(
            (element) =>
                element.type === "video"
        )
            .forEach((element) => {
                const video = document.getElementById(element.properties.elementId);
                if (isHtmlVideoElement(video)) {
                    const videoTime = (this.currentTimeInMs - element.timeFrame.start) / 1000;
                    video.currentTime = videoTime === 0 ? videoTime + 1 : videoTime;
                    if (this.playing) {
                        video.play();
                    } else {
                        video.pause();
                    }
                }
            })
    }

    updateAudioElements() {
        this.editorElements.filter(
            (element) =>
                element.type === "audio"
        )
            .forEach((element) => {
                const audio = document.getElementById(element.properties.elementId);
                if (isHtmlAudioElement(audio)) {
                    const audioTime = (this.currentTimeInMs - element.timeFrame.start) / 1000;
                    audio.currentTime = audioTime;
                    if (this.playing && (this.currentTimeInMs < element.timeFrame.end) && (this.currentTimeInMs >= element.timeFrame.start)) {
                        if (audio.paused) {
                            audio.play().catch((error) => {
                                console.error('Failed to play audio:', error);
                            });
                        }
                    } else {
                        if (!audio.paused) {
                            audio.pause();
                        }
                    }
                }
            })
    }

    updateTextToSpeechElement() {
        this.editorElements.filter(
            (element) =>
                element.type === "audio"
        )
            .forEach((element) => {

            })
    }

    setVideoFormat(format) {
        this.selectedVideoFormat = format;
    }

    saveCanvasToVideoWithAudio() {
        this.saveCanvasToVideoWithAudioWebmMp4();
    }

    saveCanvasToVideoWithAudioWebmMp4() {
        let mp4 = this.selectedVideoFormat === 'mp4'
        const canvas = document.getElementById("canvas");
        const stream = canvas.captureStream(30);
        const audioElements = this.editorElements.filter(isEditorAudioElement)
        const audioStreams = [];
        audioElements.forEach((audio) => {
            const audioElement = document.getElementById(audio.properties.elementId);
            let ctx = new AudioContext();
            let sourceNode = ctx.createMediaElementSource(audioElement);
            let dest = ctx.createMediaStreamDestination();
            sourceNode.connect(dest);
            sourceNode.connect(ctx.destination);
            audioStreams.push(dest.stream);
        });
        audioStreams.forEach((audioStream) => {
            stream.addTrack(audioStream.getAudioTracks()[0]);
        });

        const video = document.createElement("video");

        video.height = 500;
        video.width = 800;

        video.play().then(() => {
            const mediaRecorder = new MediaRecorder(stream);
            const chunks = [];
            mediaRecorder.ondataavailable = function (e) {
                chunks.push(e.data);
            };

            mediaRecorder.onstop = async function (e) {
                const blob = new Blob(chunks, { type: "video/webm" });

                if (mp4) {
                    // lets use ffmpeg to convert webm to mp4
                    const data = new Uint8Array(await (blob).arrayBuffer());
                    const ffmpeg = new FFmpeg();
                    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd"
                    await ffmpeg.load({
                        // coreURL: await toBlobURL(${baseURL}/ffmpeg-core.js, 'text/javascript'),
                        // wasmURL: await toBlobURL(${baseURL}/ffmpeg-core.wasm, 'application/wasm'),
                        // workerURL: await toBlobURL(${baseURL}/ffmpeg-core.worker.js, 'text/javascript'),
                    });
                    await ffmpeg.writeFile('video.webm', data);
                    await ffmpeg.exec(["-y", "-i", "video.webm", "-c", "copy", "video.mp4"]);
                    // await ffmpeg.exec(["-y", "-i", "video.webm", "-c:v", "libx264", "video.mp4"]);

                    const output = await ffmpeg.readFile('video.mp4');
                    const outputBlob = new Blob([output], { type: "video/mp4" });
                    const outputUrl = URL.createObjectURL(outputBlob);
                    const a = document.createElement("a");
                    a.download = "video.mp4";
                    a.href = outputUrl;
                    a.click();

                    const getOutput = await ffmpeg.deleteFile('video.mp4');

                } else {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "video.webm";
                    a.click();
                }

            };
            mediaRecorder.start();
            setTimeout(() => {
                mediaRecorder.stop();
            }, this.maxTime);
            video.remove();
        })
    }

    videoZoomHandler(zoomValue) {
        this.videoSizePercentage = zoomValue;
        this.refreshElements();
    }

    refreshElements() {
        const store = this;
        if (!store.canvas) return;
        const canvas = store.canvas;
        store.canvas.remove(...store.canvas.getObjects());

        // console.log(store.editorElements, "this is editor element in refresh elements")

        for (let index = 0; index < store.editorElements.length; index++) {
            const element = store.editorElements[index];
            switch (element.type) {
                case "video": {

                    if (document.getElementById(element.properties.elementId) == null) continue;

                    const videoElement = document.getElementById(element.properties.elementId);
                    if (!isHtmlVideoElement(videoElement)) continue;

                    let updatedVideoWidth, updatedVideoHeight;
                    const canvas = this.canvas;

                    const video = document.getElementById(element.properties.elementId);
                    const container = document.getElementById('container');

                    const parentElement = container.parentElement;
                    const parentElementStyle = window.getComputedStyle(parentElement);
                    const paddingLeft = parseFloat(parentElementStyle.paddingLeft);
                    const paddingRight = parseFloat(parentElementStyle.paddingRight);
                    const paddingTop = parseFloat(parentElementStyle.paddingTop);
                    const paddingBottom = parseFloat(parentElementStyle.paddingBottom);
                    const parentWidth = parentElement.clientWidth - (paddingLeft + paddingRight);
                    const parentHeight = parentElement.clientHeight - (paddingTop + paddingBottom);

                    if (video.videoWidth != 0 && video.videoWidth === video.videoHeight) {
                        // if (video.videoWidth < parentWidth) {
                        console.log(this.videoSizePercentage, "this is video size percentage1")
                        let finalVideoSizePerc
                        if (this.videoSizePercentage === 0) {
                            console.log(parentHeight, "this is parent height", video.videoWidth, "this is video width")
                            const calc = (parentHeight / video.videoWidth) * 100;
                            finalVideoSizePerc = Number(calc.toFixed(2));
                            console.log(calc, "this is calc", finalVideoSizePerc, "this is final video size perc")
                            this.videoSizePercentage = finalVideoSizePerc
                        } else {
                            finalVideoSizePerc = this.videoSizePercentage;
                        }
                        const scale = finalVideoSizePerc / 100;
                        const totalWidth = video.videoWidth * scale;
                        const totalHeight = video.videoHeight * scale;
                        console.log(this.videoSizePercentage, "this is video size percentage")
                        console.log(totalWidth, totalHeight, "this is total width and height")
                        container.style.width = `${totalWidth}px`;
                        const videoAspectRatio = totalWidth / totalHeight;
                        videoElement.width = totalWidth;
                        videoElement.height = totalWidth / videoAspectRatio;
                        container.style.height = `${totalWidth / videoAspectRatio}px`;
                        updatedVideoWidth = totalWidth;
                        updatedVideoHeight = totalWidth / videoAspectRatio;

                    }
                    else if (video.videoWidth != 0 && video.videoWidth > video.videoHeight) {
                        let finalVideoSizePerc
                        if (this.videoSizePercentage === 0) {
                            const calc = (parentHeight / video.videoHeight) * 100;
                            finalVideoSizePerc = Number(calc.toFixed(2));
                        } else {
                            finalVideoSizePerc = this.videoSizePercentage;
                        }
                        const scale = finalVideoSizePerc / 100;
                        // if (video.videoWidth < (parentWidth * scale)) {                            
                        const totalWidth = video.videoWidth * scale
                        const totalHeight = video.videoHeight * scale

                        if (totalWidth > parentWidth) {
                            // if()
                            let adjustedVideoPerc
                            if (this.videoSizePercentage === 0) {
                                const innerCalc = (parentWidth / video.videoWidth) * 100;
                                adjustedVideoPerc = Number(innerCalc.toFixed(2));
                            } else {
                                adjustedVideoPerc = this.videoSizePercentage
                            }
                            function findPerfectSize(lastVideoPercentage, videoZoomHandler) {
                                console.log("in recursion")
                                const calculateSize = video.videoWidth * (lastVideoPercentage / 100)
                                if (calculateSize > totalWidth) {
                                    findPerfectSize(Number(lastVideoPercentage - 0.01))
                                } else {
                                    const finalScale = lastVideoPercentage / 100
                                    const finalWidth = video.videoWidth * finalScale
                                    const finalHeight = video.videoHeight * finalScale
                                    container.style.width = `${finalWidth}px`;
                                    const videoAspectRatio = finalWidth / finalHeight;
                                    videoElement.width = finalWidth;
                                    videoElement.height = finalWidth / videoAspectRatio;
                                    container.style.height = `${finalWidth / videoAspectRatio}px`;
                                    updatedVideoWidth = finalWidth;
                                    updatedVideoHeight = finalWidth / videoAspectRatio;
                                    console.log(finalScale, "this is final scale")
                                    return lastVideoPercentage
                                }
                            }
                            const returnedValue = findPerfectSize(adjustedVideoPerc)
                            console.log(returnedValue, "this is returned value")
                            this.videoSizePercentage = returnedValue
                            console.log(this.videoSizePercentage, "returned video size percentage")
                        } else {
                            container.style.width = `${totalWidth}px`;
                            const videoAspectRatio = totalWidth / totalHeight;
                            videoElement.width = totalWidth;
                            videoElement.height = totalWidth / videoAspectRatio;
                            container.style.height = `${totalWidth / videoAspectRatio}px`;
                            updatedVideoWidth = totalWidth;
                            updatedVideoHeight = totalWidth / videoAspectRatio;
                            this.videoSizePercentage = finalVideoSizePerc
                        }

                        // }
                        // else {
                        //     console.log("in this this thing come 2");
                        //     console.log(this.videoSizePercentage, "this is video size percentage")
                        //     let diffrence
                        //     if(this.videoSizePercentage === 0 || this.videoSizePercentage === false || this.videoSizePercentage === null || this.videoSizePercentage === undefined){
                        //         console.log("come in if")
                        //         const calc = (container.clientWidth / video.videoWidth) * 100;
                        //         diffrence = Number(calc.toFixed(2));
                        //         this.videoSizePercentage = diffrence
                        //     }else {
                        //         diffrence = this.videoSizePercentage;
                        //     }
                        //     const innerScale = diffrence / 100;
                        //     const totalWidth = Number(video.videoWidth * innerScale).toFixed(0);
                        //     const totalHeight = Number(video.videoHeight * innerScale).toFixed(0);
                        //     container.style.width = `${totalWidth}px`;
                        //     const videoAspectRatio = totalWidth / totalHeight;
                        //     videoElement.width = totalWidth;
                        //     videoElement.height = totalWidth / videoAspectRatio;
                        //     container.style.height = `${totalWidth / videoAspectRatio}px`;
                        //     updatedVideoWidth = totalWidth;
                        //     updatedVideoHeight = totalWidth / videoAspectRatio;
                        // }

                    }
                    else if (video.videoWidth != 0 && video.videoWidth < video.videoHeight) {
                        let finalVideoSizePerc
                        if (this.videoSizePercentage === 0) {
                            const calc = (parentHeight / video.videoHeight) * 100;
                            finalVideoSizePerc = Number(calc.toFixed(2));
                        } else {
                            finalVideoSizePerc = this.videoSizePercentage;
                        }
                        const scale = finalVideoSizePerc / 100;
                        // if (video.videoWidth < (parentWidth * scale)) {                            
                        const totalWidth = video.videoWidth * scale
                        const totalHeight = video.videoHeight * scale

                        if (totalWidth > parentWidth) {
                            // if()
                            let adjustedVideoPerc
                            if (this.videoSizePercentage === 0) {
                                const innerCalc = (parentWidth / video.videoWidth) * 100;
                                adjustedVideoPerc = Number(innerCalc.toFixed(2));
                            } else {
                                adjustedVideoPerc = this.videoSizePercentage
                            }
                            function findPerfectSize(lastVideoPercentage, videoZoomHandler) {
                                console.log("in recursion")
                                const calculateSize = video.videoWidth * (lastVideoPercentage / 100)
                                if (calculateSize > totalWidth) {
                                    findPerfectSize(Number(lastVideoPercentage - 0.01))
                                } else {
                                    const finalScale = lastVideoPercentage / 100
                                    const finalWidth = video.videoWidth * finalScale
                                    const finalHeight = video.videoHeight * finalScale
                                    container.style.width = `${finalWidth}px`;
                                    const videoAspectRatio = finalWidth / finalHeight;
                                    videoElement.width = finalWidth;
                                    videoElement.height = finalWidth / videoAspectRatio;
                                    container.style.height = `${finalWidth / videoAspectRatio}px`;
                                    updatedVideoWidth = finalWidth;
                                    updatedVideoHeight = finalWidth / videoAspectRatio;
                                    console.log(finalScale, "this is final scale")
                                    return lastVideoPercentage
                                }
                            }
                            const returnedValue = findPerfectSize(adjustedVideoPerc)
                            console.log(returnedValue, "this is returned value")
                            this.videoSizePercentage = returnedValue
                            console.log(this.videoSizePercentage, "returned video size percentage")
                        } else {
                            container.style.width = `${totalWidth}px`;
                            const videoAspectRatio = totalWidth / totalHeight;
                            videoElement.width = totalWidth;
                            videoElement.height = totalWidth / videoAspectRatio;
                            container.style.height = `${totalWidth / videoAspectRatio}px`;
                            updatedVideoWidth = totalWidth;
                            updatedVideoHeight = totalWidth / videoAspectRatio;
                            this.videoSizePercentage = finalVideoSizePerc
                        }
                    }

                    // this.videoSizePercentage = (container.clientWidth / video.videoWidth) * 100;
                    // const containerWidth = container.clientWidth;
                    // const videoAspectRatio = video.videoWidth / video.videoHeight;
                    // videoElement.width = containerWidth;
                    // videoElement.height = containerWidth / videoAspectRatio;
                    // container.style.height = `${containerWidth / videoAspectRatio}px`;

                    // // canvas.width = containerWidth;
                    // // canvas.height = containerWidth / videoAspectRatio;
                    // updatedVideoWidth = containerWidth;
                    // updatedVideoHeight = containerWidth / videoAspectRatio;



                    // Update canvas size
                    if (canvas) {
                        // console.log(updatedVideoWidth, updatedVideoHeight, "this is updated")
                        canvas.setWidth(updatedVideoWidth);
                        canvas.setHeight(updatedVideoHeight);
                        const videoObject = this.createVideoObject(videoElement, element);


                        element.fabricObject = videoObject;
                        element.properties.imageObject = videoObject;
                        // element.properties.width = videoElement.videoWidth
                        // element.properties.height = videoElement.videoWidth

                        // Set the width and height of the video element
                        // videoElement.width = 100;
                        // videoElement.height = (updatedVideoHeight * 100) / updatedVideoWidth;


                        canvas.add(videoObject);
                        canvas.sendToBack(videoObject)

                        canvas.on("object:modified", function (e) {
                            if (!e.target) return;
                            const target = e.target;
                            if (target !== videoObject) return;
                            const placement = element.placement;
                            const newPlacement = {
                                ...placement,
                                x: target.left ?? placement.x,
                                y: target.top ?? placement.y,
                                rotation: target.angle ?? placement.rotation,
                                width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
                                height: target.height && target.scaleY ? target.height * target.scaleY : placement.height,
                                scaleX: 1,
                                scaleY: 1,
                            };
                            const newElement = {
                                ...element,
                                placement: newPlacement,
                            };
                            store.updateEditorElement(newElement);
                        });

                        videoObject.on('mousedown', (e) => {
                            store.setSelectedElement(element)
                        })
                    }

                    break;
                }
                case "image": {
                    if (document.getElementById(element.properties.elementId) == null)
                        continue;
                    const imageElement = document.getElementById(
                        element.properties.elementId
                    );
                    if (!isHtmlImageElement(imageElement)) continue;
                    // const filters = [];
                    // if (element.properties.effect?.type === "blackAndWhite") {
                    //   filters.push(new fabric.Image.filters.Grayscale());
                    // }
                    const imageObject = new fabric.CoverImage(imageElement, {
                        name: element.id,
                        left: element.placement.x,
                        top: element.placement.y,
                        angle: element.placement.rotation,
                        objectCaching: false,
                        selectable: true,
                        lockUniScaling: true,
                        // filters
                        // @ts-ignore
                        customFilter: element.properties.effect.type,
                    });

                    // imageObject.applyFilters();
                    element.fabricObject = imageObject;
                    element.properties.imageObject = imageObject;
                    const image = {
                        w: imageElement.naturalWidth,
                        h: imageElement.naturalHeight,
                    };
                    imageObject.width = image.w;
                    imageObject.height = image.h;
                    imageElement.width = image.w;
                    imageElement.height = image.h;
                    imageObject.scaleToHeight(image.w);
                    imageObject.scaleToWidth(image.h);
                    const toScale = {
                        x: element.placement.width / image.w,
                        y: element.placement.height / image.h,
                    };

                    imageObject.scaleX = toScale.x * element.placement.scaleX;
                    imageObject.scaleY = toScale.y * element.placement.scaleY;
                    canvas.add(imageObject);
                    canvas.on("object:modified", function (e) {
                        if (!e.target) return;
                        const target = e.target;
                        if (target !== imageObject) return;
                        const placement = element.placement;
                        let fianlScale = 1;
                        if (target.scaleX && target.scaleX > 0) {
                            fianlScale = target.scaleX / toScale.x;
                        }

                        const newPlacement = {
                            ...placement,
                            x: target.left ?? placement.x,
                            y: target.top ?? placement.y,
                            rotation: target.angle ?? placement.rotation,
                            scaleX: fianlScale,
                            scaleY: fianlScale,
                        };

                        const newElement = {
                            ...element,
                            placement: newPlacement,
                        };
                        store.updateEditorElement(newElement);
                    });
                    break;
                }
                case "audio": {
                    // if(document.getElementById(element.properties.elementId) === null) continue

                    // const audioElement = document.getElementById(element.properties.elementId)
                    // if(!isHtmlAudioElement(audioElement)) continue;

                    // const canvas = this.canvas

                    // if(canvas){
                    // 	const audioObject = new fabric.
                    // 	element.fabricObject = audioElement
                    // }

                    break;
                }
                case "text": {
                    if (element.isVisible === true) {


                        const textObject = new CustomRectText({
                            text: element.properties.text,
                            x: element.placement.x,
                            y: element.placement.y,
                            fontSize: element.properties.fontSize,
                            fontFamily: element.properties.fontFamily,
                            fontWeight: element.properties.fontWeight,
                            textColor: element.properties.textColor,
                            selectable: true,
                            // animationType: element.properties.animationType,
                            backgroundColor: element.properties.backgroundColor,
                            width: element.properties.width,
                            height: element.properties.height,
                            underline: element.properties.underline,
                            fontStyle: element.properties.fontStyle,
                            textAlign: element.properties.textAlign,
                            borderRadius: element.properties.borderRadius,
                            scaleX: element.placement.scaleX,
                            scaleY: element.placement.scaleY,
                            lineHeight: element.properties.lineHeight,
                            charSpacing: element.properties.charSpacing,
                            txtOpacity: element.properties.txtOpacity,
                            btnOpacity: element.properties.btnOpacity,
                            outline: element.properties.outline,
                            outlineWidth: element.properties.outlineWidth,
                            angle: element.properties.angle,
                            fixedWidth: element.properties.fixedWidth,
                            // originX : 'right'
                            paddingX: element.properties.paddingX,
                            paddingY: element.properties.paddingY,
                            stroke: element.properties.stroke,
                            strokeWidth: element.properties.strokeWidth || 0,
                        }, canvas)

                        element.fabricObject = textObject;

                        canvas.add(textObject);
                        textObject.on('mousedown', (e) => {
                            this.setSelectedElement(element)
                            canvas.setActiveObject(textObject)
                            if (e.target) {
                                store.setSelectedMenuOption("TextPanel")
                            }
                        })

                        const menu = [

                            // { name: 'Merge Tags', id: 'mergetags', icon: merge_icon },
                            // { name: 'Up', id: 'Up' },
                            // { name: 'Down', id: 'Down' },
                            // { name: 'Duplicate', id: 'Duplicate', icon: copy },
                            { name: 'Remove', id: 'Remove', icon: deleteicone },
                            { name: 'Animation', id: 'Animation', icon: animationicon }
                            // Add more employees as needed
                        ];

                        // const data = JSON.parse(localStorage.getItem("emp"));
                        // const data = (localStorage.getItem("emp"));
                        const dataList = this.singleCampaignData.headers

                        let employees = [];

                        if (dataList) {
                            const mergeData = dataList;
                            if (Array.isArray(mergeData) && mergeData.length > 0) {
                                employees = mergeData;
                            }
                        }
                        // const employees = ["Employee", "District", "Contact Number"];
                        const generalPopup = document.createElement("div");
                        generalPopup.style.position = "absolute";
                        generalPopup.style.backgroundColor = "#272836";
                        generalPopup.style.borderRadius = "4px"
                        generalPopup.style.display = "flex";
                        generalPopup.style.gap = "5px";
                        generalPopup.style.color = "#FFFFFF";
                        generalPopup.style.border = "1px solid #3B3B4F"
                        // document.body.appendChild(generalPopup);

                        const mergeTagsPopup = document.createElement("div");
                        mergeTagsPopup.style.position = "absolute";
                        mergeTagsPopup.style.backgroundColor = "#272836";
                        mergeTagsPopup.style.borderRadius = "4px"
                        mergeTagsPopup.style.color = "#FFFFFF"
                        mergeTagsPopup.style.display = "none";
                        mergeTagsPopup.style.gap = "5px";
                        mergeTagsPopup.style.border = "1px solid #3B3B4F"
                        document.body.appendChild(mergeTagsPopup);

                        menu.forEach((obj, i) => {
                            const item = document.createElement("div");

                            item.style.padding = `10px`;
                            item.style.display = 'flex';
                            item.style.fontSize = "14px";
                            item.style.fontFamily = "Inter";
                            item.style.fontWeight = "500"
                            item.style.alignItems = "center";
                            item.style.cursor = "pointer"
                            if (i + 1 !== menu.length) {
                                item.style.borderRight = "1px solid #3B3B4F";
                            }

                            const icon = document.createElement("img");
                            icon.src = obj.icon
                            icon.style.marginRight = "5px"


                            item.appendChild(icon)
                            item.appendChild(document.createTextNode(obj.name));
                            // item.textContent = `${obj.name}`;

                            generalPopup.appendChild(item);

                            //click on each name
                            item.addEventListener("click", () => {
                                switch (obj.id) {
                                    case "Remove":
                                        canvas.remove(textObject);
                                        store.removeEditorElement(element.id);
                                        store.setSelectedMenuOption("NoSelectedItem");
                                        break;

                                    case "mergetags":
                                        // Show merge tags popup
                                        mergeTagsPopup.style.left = generalPopup.style.left;
                                        mergeTagsPopup.style.top = generalPopup.style.top;
                                        mergeTagsPopup.style.display = "flex";
                                        // Clear existing content
                                        mergeTagsPopup.innerHTML = "";

                                        if (employees.length === 0 || employees.length === undefined) {
                                            mergeTagsPopup.style.display = "none";
                                            break;
                                        }

                                        employees.forEach((employee, i) => {
                                            const employeeField = document.createElement("div");
                                            employeeField.textContent = employee;
                                            employeeField.classList.add("employee-field");

                                            const smallText = document.createElement("span");

                                            if (i + 1 !== employees.length) {
                                                employeeField.style.borderRight = "1px solid #3B3B4F";
                                            }

                                            employeeField.style.padding = "10px";
                                            employeeField.appendChild(smallText);

                                            employeeField.addEventListener("mousedown", () => {
                                                if (textObject) {
                                                    const iTextObject = textObject._objects.find(obj => obj.type === 'i-text');

                                                    if (iTextObject) {
                                                        iTextObject.set('text', iTextObject.text + `{{${employee}}} `);
                                                        canvas.renderAll();
                                                        mergeTagsPopup.style.display = "none";
                                                    } else {
                                                        console.error("No i-text object found within CustomText group.");
                                                    }
                                                } else {
                                                    console.error("No CustomText group object found in hhh.objects.");
                                                }
                                            });

                                            mergeTagsPopup.appendChild(employeeField);
                                        });
                                        break;

                                    case "Animation":
                                        store.setSelectedMenuOption("Animation")
                                        break;
                                    default:
                                        break;
                                }
                                generalPopup.style.display = "none";
                            });
                        });

                        textObject.on("mousedblclick", function (e) {
                            document.body.appendChild(generalPopup);
                            generalPopup.style.left = e.e.clientX + "px";
                            generalPopup.style.top = e.e.clientY + "px";
                            generalPopup.style.display = "flex";
                        });

                        canvas.on("mouse:down", function () {
                            generalPopup.style.display = "none";
                        })

                        canvas.on("object:modified", function (e) {

                            if (!e.target) return;
                            const target = e.target;

                            if (!target) return
                            if (target !== textObject) return;

                            const fromLeft = (target.left / canvas.width) * 100
                            const fromTop = (target.top / canvas.height) * 100

                            const newElement = {
                                ...element,
                                placement: {
                                    x: fromLeft ?? element.placement.x,
                                    y: fromTop ?? element.placement.y,
                                    rotation: target.angle ?? element.placement.rotation,
                                    scaleX: target.scaleX ?? element.placement.scaleX,
                                    scaleY: target.scaleY ?? element.placement.scaleY,
                                },
                                properties: {
                                    ...element.properties,
                                },
                            };
                            store.updateEditorElement(newElement);
                        });

                    }
                    break;
                }
                case "button": {

                    if (element.isVisible === true) {
                        const buttonObject = new CustomRectButton({
                            x: element.placement.x,
                            y: element.placement.y,
                            fontSize: element.properties.fontSize,
                            fontFamily: element.properties.fontFamily,
                            fontWeight: element.properties.fontWeight,
                            // fill: element.properties.fill,
                            textColor: element.properties.textColor,
                            selectable: true,
                            linkUrl: element.properties.linkUrl,
                            backgroundColor: element.properties.backgroundColor,
                            width: "",
                            height: "",
                            underline: element.properties.underline,
                            fontStyle: element.properties.fontStyle,
                            textAlign: element.properties.textAlign,
                            strokeWidth: element.properties.strokeWidth || 0,
                            stroke: element.properties.stroke,
                            borderRadius: element.properties.borderRadius,
                            btnOpacity: element.properties.btnOpacity,
                            txtOpacity: element.properties.txtOpacity,
                            lineHeight: element.properties.lineHeight,
                            charSpacing: element.properties.charSpacing,
                            scaleX: element.placement.scaleX,
                            scaleY: element.placement.scaleY,
                            paddingX: element.properties.paddingX,
                            paddingY: element.properties.paddingY,
                            text: element.properties.text
                        }, canvas);

                        element.fabricObject = buttonObject;
                        canvas.add(buttonObject);

                        element.fabricObject = buttonObject
                        // Add event listener for button click
                        buttonObject.on('mousedblclick', () => {
                            if (buttonObject.linkUrl) {
                                window.open(buttonObject.linkUrl, '_blank')
                            }
                        });

                        buttonObject.on('mousedown', (e) => {
                            this.setSelectedElement(element)
                            canvas.setActiveObject(buttonObject)
                            store.setSelectedMenuOption("Button Link")
                        })

                        canvas.on("object:modified", function (e) {
                            if (!e.target) return;
                            const target = e.target;

                            if (!target) return
                            if (target != buttonObject) return;

                            const fromLeft = (target.left / canvas.width) * 100
                            const fromTop = (target.top / canvas.height) * 100

                            const newElement = {
                                ...element,
                                placement: {
                                    x: fromLeft ?? element.placement.x,
                                    y: fromTop ?? element.placement.y,
                                    rotation: target.angle ?? element.placement.rotation,
                                    scaleX: target.scaleX ?? element.placement.scaleX,
                                    scaleY: target.scaleY ?? element.placement.scaleY,
                                },
                                properties: {
                                    ...element.properties,
                                },
                            };

                            store.updateEditorElement(newElement);
                        })
                    }
                    break;
                }
                // case "audio": {
                //     if (element.isVisible === true) {
                //     }
                //     break;
                // }
                default: {
                    throw new Error("Not implemented");
                }
            }
            if (element.fabricObject) {
                element.fabricObject.on("selected", function (e) {
                    store.setSelectedElement(element);
                });
            }
        }

        const selectedEditorElement = store.selectedElement;
        if (selectedEditorElement && selectedEditorElement.fabricObject) {
            canvas.setActiveObject(selectedEditorElement.fabricObject);
        }
        this.refreshAnimations();
        this.updateTimeTo(this.currentTimeInMs);
        store.canvas.renderAll();
    }

    // ----------------------------  VIDEO PLAYER'S PAGE CODE START ---------------------------------------------//

    addVideoForPlayer(url, volume, data) {

        const videoElement = document.createElement('video')

        videoElement.addEventListener('loadedmetadata', () => {

            this.videoDuration = videoElement.duration
            const videoVolume = videoElement.volume;

            this.addEditorElement({
                id: data.id,

                isVisible: true,
                name: data.name,
                type: "video",
                placement: {
                    x: data.placement.x,
                    y: data.placement.y,
                    width: data.placement.width,
                    height: data.placement.height,
                    rotation: data.placement.rotation,
                    scaleX: data.placement.scaleX,
                    scaleY: data.placement.scaleY,
                },
                timeFrame: {
                    start: 0,
                    end: this.videoDuration * 1000,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    elementId: `video-${data.id}`,
                    src: url,
                    effect: {
                        type: "none",
                    },
                    volume: videoVolume,
                    width: 0,
                    height: 0,

                },
            });
            this.maxTime = this.videoDuration * 1000
            // this.currentKeyFrame = 0
        })

        videoElement.src = url

    }

    addTextForPlayer(data) {

        const id = data.id
        const index = this.editorElements.length;
        const canvas = this.canvas
        if (!canvas) return;

        const text = new CustomText(data.properties.text, {

            left: data.placement.x,
            top: data.placement.y,
            fontSize: data.properties.fontSize,
            fontFamily: data.properties.fontFamily,
            fontWeight: data.properties.fontWeight,
            textColor: data.properties.textColor,
            selectable: false,
            animationType: data.properties.animationType,
            backgroundColor: data.properties.backgroundColor,
            width: data.properties.width,
            height: data.properties.height,
            underline: data.properties.underline,
            fontStyle: data.properties.fontStyle,
            textAlign: data.properties.textAlign,
            borderRadius: data.properties.borderRadius,
            scaleX: data.placement.scaleX,
            scaleY: data.placement.scaleY,
            lineHeight: data.properties.lineHeight,
            charSpacing: data.properties.charSpacing,
            txtOpacity: data.properties.txtOpacity,
            btnOpacity: data.properties.btnOpacity,
            outline: data.properties.outline,
            outlineWidth: data.properties.outlineWidth,
            angle: data.properties.angle,
            lockMovementX: true,
            lockMovementY: true

            // angle: 90
            // angle: options.angle,
        })


        canvas.add(text)
        if (!canvas) {
        }

        this.addEditorElement(
            {
                id,
                isVisible: true,
                name: `Text ${index + 1}`,
                type: "text",
                placement: {
                    x: data.placement.x,
                    y: data.placement.y,
                    width: data.placement.width,
                    height: data.placement.height,
                    rotation: data.placement.rotation,
                    scaleX: data.placement.scaleX,
                    scaleY: data.placement.scaleY,
                },
                timeFrame: {
                    start: data.timeFrame.start,
                    end: data.timeFrame.end,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    animationType: data.properties.animationType,
                    text: data.properties.text,
                    fontSize: data.properties.fontSize,
                    fontWeight: data.properties.fontWeight,
                    textColor: data.properties.textColor,
                    backgroundColor: data.properties.backgroundColor,
                    fontFamily: data.properties.fontFamily,
                    width: data.properties.width,
                    height: data.properties.height,
                    underline: data.properties.underline,
                    fontStyle: data.properties.fontStyle,
                    textAlign: data.properties.textAlign,
                    borderRadius: data.properties.borderRadius,
                    lineHeight: data.properties.lineHeight,
                    charSpacing: data.properties.charSpacing,
                    txtOpacity: data.properties.txtOpacity,
                    btnOpacity: data.properties.btnOpacity,
                    outline: data.properties.outline,
                    outlineWidth: data.properties.outlineWidth,
                    angle: data.properties.angle
                },
            });


    }

    addTextToSpeechForPlayer(data) {
        const audioElement = document.createElement('audio')
        audioElement.src = data.properties.src
        audioElement.controls = false

        audioElement.onloadedmetadata = () => {
            const audioDurationMs = audioElement.duration * 1000

            if (isNaN(audioDurationMs)) {
                return;
            }

            this.audioDurationMs = audioDurationMs

            const canvas = this.canvas

            if (!canvas) {
                return
            }

            const fabricAudio = new fabric.Textbox('Audio Element', {
                left: 100,
                top: 100,
                width: 200,
                height: 50,
                fill: 'transparent',
                hasControls: false,
                hasBorders: false,
                selectable: false,
                fontFamily: 'Arial',
                fontSize: 14,
                originX: 'left',
                originY: 'top',
            })

            canvas.add(fabricAudio)

            const id = data.id
            this.addEditorElement({
                id: data.id,
                name: `Media(audio)`,
                fabricObject: fabricAudio,
                isVisible: true,
                type: "audio",
                placement: {
                    x: data.placement.x,
                    y: data.placement.y,
                    width: data.placement.width,
                    height: data.placement.height,
                    rotation: data.placement.rotation,
                    scaleX: data.placement.scaleX,
                    scaleY: data.placement.scaleY,
                },
                timeFrame: {
                    start: data.timeFrame.start,
                    end: data.timeFrame.end,
                    UpdateStart: 0,
                    UpdateEnd: 0
                },
                properties: {
                    elementId: data.properties.elementId,
                    src: audioElement.src,
                    text: data.properties.text,
                },
            });
        }
    }

    addButtonForPlayer(data) {

        const id = data.id
        const canvas = this.canvas
        if (!canvas) return;

        const button = new CustomButton(data.properties.text, {
            left: data.placement.x,
            top: data.placement.y,
            fontSize: data.properties.fontSize,
            fontFamily: data.properties.fontFamily,
            fontWeight: data.properties.fontWeight,
            fill: data.properties.fill,
            selectable: false,
            linkUrl: data.properties.linkUrl,
            backgroundColor: data.properties.backgroundColor,
            width: data.properties.width,
            height: data.properties.height,
            underline: data.properties.underline,
            fontStyle: data.properties.italic,
            textAlign: data.properties.textAlign,
            strokeWidth: data.properties.border,
            stroke: data.properties.borderColor,
            borderRadius: data.properties.borderRadius,
            btnOpacity: data.properties.btnOpacity,
            txtOpacity: data.properties.txtOpacity,
            charSpacing: data.properties.charSpacing,
            lineHeight: data.properties.lineHeight,
            scaleX: data.placement.scaleX,
            scaleY: data.placement.scaleY,
        });

        canvas.add(button)
        if (!canvas) {
            return
        }

        this.addEditorElement({
            id,
            isVisible: true,
            name: `Button${1}`,
            type: "button",
            placement: {
                x: data.placement.x,
                y: data.placement.y,
                width: data.placement.width,
                height: data.placement.height,
                rotation: data.placement.rotation,
                scaleX: data.placement.scaleX,
                scaleY: data.placement.scaleY,
            },
            timeFrame: {
                start: data.timeFrame.start,
                end: data.timeFrame.end,
                UpdateStart: 0,
                UpdateEnd: 0
            },
            properties: {
                text: data.properties.text,
                fontSize: data.properties.fontSize,
                fontWeight: data.properties.fontWeight,
                textColor: data.properties.textColor,
                backgroundColor: data.properties.backgroundColor,
                fontFamily: data.properties.fontFamily,
                linkUrl: data.properties.linkUrl,
                width: data.properties.width,
                height: data.properties.height,
                underline: data.properties.underline,
                fontStyle: data.properties.fontStyle,
                textAlign: data.properties.textAlign,
                strokeWidth: data.properties.strokeWidth || 0,
                stroke: data.properties.stroke,
                borderRadius: data.properties.borderRadius,
                alignment: data.properties.alignment,
                btnOpacity: data.properties.btnOpacity,
                txtOpacity: data.properties.txtOpacity,
                lineHeight: data.properties.lineHeight,
                charSpacing: data.properties.charSpacing
            },
        });
    }

    // ---------------------------------VIDEO PLAYER'S PAGE CODE END-----------------------------------------------// 
}

export function isEditorAudioElement(
    element
) {
    return element.type === "audio";
}

export function isEditorVideoElement(
    element
) {
    return element.type === "video";
}

export function isEditorImageElement(
    element
) {
    return element.type === "image";
}

export function isEditorTextElement(
    element
) {
    return element.type === "text";
}

export function isEditorButtonElement(
    element
) {
    return element.type === "button";
}

function getTextObjectsPartitionedByCharacters(textObject, element) {
    let copyCharsObjects = [];
    // replace all line endings with blank
    const characters = (textObject.text ?? "").split('').filter((m) => m !== '\n');
    const charObjects = textObject.__charBounds;
    if (!charObjects) return [];
    const charObjectFixed = charObjects.map((m, index) => m.slice(0, m.length - 1).map(m => ({ m, index }))).flat();
    const lineHeight = textObject.getHeightOfLine(0);
    for (let i = 0; i < characters.length; i++) {
        if (!charObjectFixed[i]) continue;
        const { m: charObject, index: lineIndex } = charObjectFixed[i];
        const char = characters[i];
        const scaleX = textObject.scaleX ?? 1;
        const scaleY = textObject.scaleY ?? 1;
        const charTextObject = new fabric.Text(char, {
            left: charObject.left * scaleX + (element.placement.x),
            scaleX: scaleX,
            scaleY: scaleY,
            top: lineIndex * lineHeight * scaleY + (element.placement.y),

            fontSize: textObject.fontSize,
            fontWeight: textObject.fontWeight,
            fill: '#fff',
        });
        copyCharsObjects.push(charTextObject);
    }
    return copyCharsObjects;
}