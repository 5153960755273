export const hyperclipTTS = [
    {
        language: "afrikaans",
        code: "af_ZA",
        flag: "🇿🇦",
        isMaleAvailable: false,
        isFemaleAvailable: true,
        speakers: [
            { id: 1, name: "Dakarai", speaker: "8963", sample: "/afrikans/dakarai_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false },
            { id: 2, name: "Jabari", speaker: "7130", sample: "/afrikans/jabari_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false },
            { id: 3, name: "Ade", speaker: "8924", sample: "/afrikans/ade_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false },
            { id: 4, name: "Azibo", speaker: "8148", sample: "/afrikans/azibo_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false },
            { id: 5, name: "Davu", speaker: "1919", sample: "/afrikans/davu_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false },
            { id: 6, name: "Abeba", speaker: "2418", sample: "/afrikans/abeba_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false },
            { id: 7, name: "Amari", speaker: "6590", sample: "/afrikans/amari_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false },
            { id: 8, name: "Asha", speaker: "0184", sample: "/afrikans/asha_f.mp3", gender: "female", dataset: "google-nwu_low", isDefault: false }
        ]
    },
    {
        language: "Bengali",
        code: "bn",
        flag: "🇧🇩 ",
        isMaleAvailable: true,
        isFemaleAvailable: true,
        speakers: [
            { id: 0, name: "Aadal", speaker: "rm", sample: "/bengali/aadal_f.mp3", gender: "female", dataset: "multi_low", isDefault: false },
            { id: 1, name: "Aadhira", speaker: "03042", sample: "/bengali/aadhira_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 2, name: "Aahlaad", speaker: "00737", sample: "/bengali/aahlaad_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 3, name: "Aakaar", speaker: "01232", sample: "/bengali/aakaar_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 4, name: "Aakash", speaker: "02194", sample: "/bengali/aakash_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 5, name: "Aamod", speaker: "3108", sample: "/bengali/aamod_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 6, name: "Aanjaneya", speaker: "3713", sample: "/bengali/aanjaneya_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 7, name: "Aaradhak", speaker: "1010", sample: "/bengali/aaradhak_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 8, name: "Aarish", speaker: "00779", sample: "/bengali/aarish_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 9, name: "Aashish", speaker: "9169", sample: "/bengali/aashish_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 10, name: "Aashutosh", speaker: "4046", sample: "/bengali/aashutosh_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 11, name: "Abhijeet", speaker: "5958", sample: "/bengali/abhijeet_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 12, name: "Prashant", speaker: "01701", sample: "/bengali/prashant_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 13, name: "kaya", speaker: "4811", sample: "/bengali/kaya_f.mp3", gender: "female", dataset: "multi_low", isDefault: false },
            { id: 14, name: "Abhimanyu", speaker: "0834", sample: "/bengali/abhimanyu_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 15, name: "Abhirup", speaker: "3958", sample: "/bengali/abhirup_m.mp3", gender: "male", dataset: "multi_low", isDefault: false }
        ]
    },
    {
        language: "German",
        code: "de_DE",
        flag: "🇩🇪",
        isMaleAvailable: true,
        isFemaleAvailable: true,
        speakers: [
            { id: 0, name: "Adelaide", speaker: "ramona_deininger", sample: "/german/adelaide_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 1, name: "Adler", speaker: "karlsson", sample: "/german/adler_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { id: 2, name: "Aloisa", speaker: "rebecca_braunert_plunkett", sample: "/german/aloisa_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 3, name: "Amalia", speaker: "eva_k", sample: "/german/amalia_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 4, name: "Belinda", speaker: "angela_merkel", sample: "/german/belinda_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 5, name: "Manfred", speaker: "amused", sample: "/german/manfred_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { id: 6, name: "Otto", speaker: "angry", sample: "/german/otto_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { id: 7, name: "Rainer", speaker: "disgusted", sample: "/german/rainer_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { id: 8, name: "Richmond", speaker: "drunk", sample: "/german/richmond_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { id: 9, name: "Schaffer", speaker: "neutral", sample: "/german/schaffer_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { id: 10, name: "Theobald", speaker: "sleepy", sample: "/german/theobald_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { id: 11, name: "Valentin", speaker: "surprised", sample: "/german/valentin_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { id: 12, name: "Walden", speaker: "whisper", sample: "/german/walden_m.mp3", gender: "male", dataset: "thorsten-emotion_low", isDefault: false },
            { name: "Leopold", sample: "/german/leopold_m.mp3", gender: "male", dataset: "thorsten_low", isDefault: true }
        ]
    },
    {
        language: "Greek",
        code: "el_GR",
        flag: "🇬🇷",
        isMaleAvailable: false,
        isFemaleAvailable: true,
        speakers: [
            { name: "Eva", sample: "/greek/eva_f.mp3", gender: "female", dataset: "rapunzelina_low", isDefault: true }
        ]
    },
    {
        language: "English_UK",
        code: "en_UK",
        flag: "🇬🇧",
        isMaleAvailable: true,
        isFemaleAvailable: false,
        speakers: [
            { name: "Huxley", sample: "/english_uk/huxley_m.mp3", gender: "male", dataset: "apope_low", isDefault: true }
        ]
    },
    {
        language: "English_US",
        code: "en_US",
        flag: "🇺🇸",
        isMaleAvailable: true,
        isFemaleAvailable: true,
        speakers: [
            { id: 0, name: "James", speaker: "awb", sample: "/english_us/james_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 1, name: "Michael", speaker: "rms", sample: "/english_us/michael_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 2, name: "Mary", speaker: "slt", sample: "/english_us/mary_f.mp3", gender: "female" , dataset:'cmu-arctic_low', isDefault: false },
            { id: 3, name: "Robert", speaker: "ksp", sample: "/english_us/robert_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 4, name: "Patricia", speaker: "clb", sample: "/english_us/patricia_f.mp3", gender: "female", dataset: "cmu-arctic_low", isDefault: false },
            { id: 5, name: "John", speaker: "aew", sample: "/english_us/john_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 6, name: "Daid", speaker: "bdl", sample: "/english_us/daid_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 7, name: "Linda", speaker: "lnh", sample: "/english_us/linda_f.mp3", gender: "female", dataset: "cmu-arctic_low", isDefault: false },
            { id: 8, name: "William", speaker: "jmk", sample: "/english_us/william_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 9, name: "Richard", speaker: "rxr", sample: "/english_us/richard_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 10, name: "Joseph", speaker: "fem", sample: "/english_us/joseph_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 11, name: "Elizabeth", speaker: "ljm", sample: "/english_us/elizabeth_f.mp3", gender: "female", dataset: "cmu-arctic_low", isDefault: false },
            { id: 12, name: "Barbara", speaker: "slp", sample: "/english_us/barbara_f.mp3", gender: "female", dataset: "cmu-arctic_low", isDefault: false },
            { id: 13, name: "Thomas", speaker: "ahw", sample: "/english_us/thomas_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 14, name: "Mia", speaker: "axb", sample: "/english_us/mia_f.mp3", gender: "female", dataset: "cmu-arctic_low", isDefault: false },
            { id: 15, name: "Christopher", speaker: "aup", sample: "/english_us/christopher_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 16, name: "Susan", speaker: "eey", sample: "/english_us/susan_f.mp3", gender: "female", dataset: "cmu-arctic_low", isDefault: false },
            { id: 17, name: "Charles", speaker: "gka", sample: "/english_us/charles_m.mp3", gender: "male", dataset: "cmu-arctic_low", isDefault: false },
            { id: 0, name: "Daniel", speaker: "9017", sample: "/english_us/daniel_m.mp3", gender: "male", dataset: "hifi-tts_low", isDefault: false },
            { id: 1, name: "Matthew", speaker: "6097", sample: "/english_us/matthew_m.mp3", gender: "male", dataset: "hifi-tts_low", isDefault: false },
            { id: 2, name: "Jessica", speaker: "92", sample: "/english_us/jessica_f.mp3", gender: "female", dataset: "hifi-tts_low", isDefault: false },
            { id: 0, name: "Anthony", speaker: "elliot_miller", Sample: "/english_us/anthony_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { id: 1, name: "Betty", speaker: "judy_bieber", Sample: "/english_us/betty_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 2, name: "Ashley", speaker: "mary_ann", Sample: "/english_us/ashley_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 0, name: "Maria", speaker: "p239", sample: "/english_us/mary_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 1, name: "Emily", speaker: "p236", sample: "/english_us/emily_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 2, name: "Donna", speaker: "p264", sample: "/english_us/donna_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 3, name: "Michelle", speaker: "p250", sample: "/english_us/michelle_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 4, name: "Joshua", speaker: "p259", sample: "/english_us/joshua_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 5, name: "Kenneth", speaker: "p247", sample: "/english_us/kenneth_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 6, name: "Carol", speaker: "p261", sample: "/english_us/carol_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 7, name: "Kevin", speaker: "p263", sample: "/english_us/kevin_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 8, name: "Amanda", speaker: "p283", sample: "/english_us/amanda_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 9, name: "Brian", speaker: "p274", sample: "/english_us/brian_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 10, name: "Ronald", speaker: "p286", sample: "/english_us/ronald_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 11, name: "Stephanie", speaker: "p276", sample: "/english_us/stephanie_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 12, name: "George", speaker: "p270", sample: "/english_us/george_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 13, name: "Jason", speaker: "p281", sample: "/english_us/jason_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 14, name: "Sharon", speaker: "p277", sample: "/english_us/sharon_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 15, name: "Laura", speaker: "p231", sample: "/english_us/laura_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 16, name: "Cynthia", speaker: "p238", sample: "/english_us/cynthia_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 17, name: "Jeffrey", speaker: "p271", sample: "/english_us/jeffrey_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 18, name: "Dorothy", speaker: "p257", sample: "/english_us/dorothy_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 19, name: "Ryan", speaker: "p273", sample: "/english_us/ryan_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 20, name: "Jacob", speaker: "p284", sample: "/english_us/jacob_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 21, name: "Amy", speaker: "p329", sample: "/english_us/amy_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 22, name: "Kathleen", speaker: "p361", sample: "/english_us/kathleen_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 23, name: "Nicholas", speaker: "p287", sample: "/english_us/nicholas_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 24, name: "Gary", speaker: "p360", sample: "/english_us/gary_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 25, name: "Eric", speaker: "p374", sample: "/english_us/eric_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 26, name: "Diane", speaker: "p376", sample: "/english_us/diane_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 27, name: "Julie", speaker: "p310", sample: "/english_us/julie_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 28, name: "Jose", speaker: "p304", sample: "/english_us/jose_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 29, name: "Joyce", speaker: "p340", sample: "/english_us/joyce_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 30, name: "Nathan", speaker: "p347", sample: "/english_us/nathan_f.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 31, name: "Lauren", speaker: "p330", sample: "/english_us/lauren_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 32, name: "Kelly", speaker: "p308", sample: "/english_us/kelly_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 33, name: "Christina", speaker: "p314", sample: "/english_us/christina_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 34, name: "Ruth", speaker: "p317", sample: "/english_us/ruth_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 35, name: "Joan", speaker: "p339", sample: "/english_us/joan_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 36, name: "Kyle", speaker: "p311", sample: "/english_us/kyle_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 37, name: "Judith", speaker: "p294", sample: "/english_us/judith_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 38, name: "Evelyn", speaker: "p305", sample: "/english_us/evelyn_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 39, name: "Hannah", speaker: "p266", sample: "/english_us/hannah_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 40, name: "Andrea", speaker: "p335", sample: "/english_us/andrea_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 41, name: "Walter", speaker: "p334", sample: "/english_us/walter_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 42, name: "Andy", speaker: "p318", sample: "/english_us/andy_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 43, name: "Megan", speaker: "p323", sample: "/english_us/megan_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 44, name: "Cheryl", speaker: "p351", sample: "/english_us/cheryl_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 45, name: "Jacqueline", speaker: "p333", sample: "/english_us/jacqueline_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 46, name: "Teresa", speaker: "p313", sample: "/english_us/teresa_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 47, name: "Sean", speaker: "p316", sample: "/english_us/sean_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 48, name: "Tanya", speaker: "p244", sample: "/english_us/tanya_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 49, name: "Abigail", speaker: "p307", sample: "/english_us/abigail_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 50, name: "Gerald", speaker: "p363", sample: "/english_us/gerald_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 51, name: "Sophia", speaker: "p336", sample: "/english_us/sophia_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 52, name: "Martha", speaker: "p312", sample: "/english_us/martha_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 53, name: "Sara", speaker: "p267", sample: "/english_us/sara_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 54, name: "Gloria", speaker: "p297", sample: "/english_us/gloria_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 55, name: "Jordan", speaker: "p275", sample: "/english_us/jordan_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 56, name: "Janice", speaker: "p295", sample: "/english_us/janice_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 57, name: "Kathryn", speaker: "p288", sample: "/english_us/kathryn_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 58, name: "Bryan", speaker: "p258", sample: "/english_us/bryan_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 59, name: "Ann", speaker: "p301", sample: "/english_us/ann_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 60, name: "Lawrence", speaker: "p232", sample: "/english_us/lawrence_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 61, name: "Arthur", speaker: "p292", sample: "/english_us/arthur_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 62, name: "Gabriel", speaker: "p272", sample: "/english_us/gabriel_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 63, name: "Bruce", speaker: "p278", sample: "/english_us/bruce_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 64, name: "Charlotte", speaker: "p280", sample: "/english_us/charlotte_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 65, name: "Julia", speaker: "p341", sample: "/english_us/julia_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 66, name: "Grace", speaker: "p268", sample: "/english_us/grace_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 67, name: "Billy", speaker: "p298", sample: "/english_us/billy_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 68, name: "Joe", speaker: "p299", sample: "/english_us/joe_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 69, name: "Alan", speaker: "p279", sample: "/english_us/alan_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 70, name: "Juan", speaker: "p285", sample: "/english_us/juan_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 71, name: "Elijah", speaker: "p326", sample: "/english_us/elijah_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 72, name: "Denise", speaker: "p300", sample: "/english_us/denise_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 73, name: "Frances", speaker: "s5", sample: "/english_us/frances_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 74, name: "Danielle", speaker: "p230", sample: "/english_us/danielle_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 75, name: "Albert", speaker: "p254", sample: "/english_us/albert_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 76, name: "Danie", speaker: "p269", sample: "/english_us/danie_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 77, name: "Marilyn", speaker: "p293", sample: "/english_us/marilyn_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 78, name: "Wayne", speaker: "p252", sample: "/english_us/wayne_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 79, name: "Randy", speaker: "p345", sample: "/english_us/randy_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 80, name: "Natalie", speaker: "p262", sample: "/english_us/natalie_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 81, name: "Mason", speaker: "p243", sample: "/english_us/mason_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 82, name: "Vincent", speaker: "p227", sample: "/english_us/vincent_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 83, name: "Diana", speaker: "p343", sample: "/english_us/diana_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 84, name: "Liam", speaker: "p255", sample: "/english_us/liam_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 85, name: "Brittany", speaker: "p229", sample: "/english_us/brittany_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 86, name: "Theresa", speaker: "p240", sample: "/english_us/theresa_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 87, name: "Kayla", speaker: "p248", sample: "/english_us/kayla_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 88, name: "Alexis", speaker: "p253", sample: "/english_us/alexis_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 89, name: "Doris", speaker: "p233", sample: "/english_us/doris_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 90, name: "Lori", speaker: "p228", sample: "/english_us/lori_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 91, name: "Russell", speaker: "p251", sample: "/english_us/russell_f.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 92, name: "Tiffany", speaker: "p282", sample: "/english_us/tiffany_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 93, name: "Barric", speaker: "p246", sample: "/english_us/barric_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 94, name: "Bayard", speaker: "p234", sample: "/english_us/bayard_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 95, name: "Barker", speaker: "p226", sample: "/english_us/barker_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 96, name: "Bardalph", speaker: "p260", sample: "/english_us/bardalph_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 97, name: "Ballentine", speaker: "p245", sample: "/english_us/ballentine_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 98, name: "Balder", speaker: "p241", sample: "/english_us/balder_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 99, name: "Bishop", speaker: "p303", sample: "/english_us/bishop_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 100, name: "Blaan", speaker: "p265", sample: "/english_us/blaan_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 101, name: "Blackburn", speaker: "p306", sample: "/english_us/blackburn_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 102, name: "Baker", speaker: "p237", sample: "/english_us/baker_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 103, name: "Blade", speaker: "p249", sample: "/english_us/blade_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 104, name: "Blaine", speaker: "p256", sample: "/english_us/blaine_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { id: 105, name: "Avery", speaker: "p302", sample: "/english_us/avery_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 106, name: "Audie", speaker: "p364", sample: "/english_us/audie_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 107, name: "Atwell", speaker: "p225", sample: "/english_us/atwell_m.mp3", gender: "male", dataset: "vctk_low", isDefault: false },
            { id: 108, name: "Bronson", speaker: "p362", sample: "/english_us/bronson_f.mp3", gender: "female", dataset: "vctk_low", isDefault: false },
            { name: "Karen", sample: "/english_us/karen_f.mp3", gender: "female", dataset: "ljspeech_low", isDefault: true },

        ]
    },
    {
        language: "Spanish",
        code: "es_ES",
        flag: "🇪🇸",
        isMaleAvailable: true,
        isFemaleAvailable: false,
        speakers: [
            { id: 0, name: "Jose", speaker: "tux", sample: "/spanish/jose_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { id: 1, name: "Matias", speaker: "victor_villarraza", sample: "/spanish/matias_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { id: 2, name: "Mateo", speaker: "karen_savage", sample: "/spanish/mateo_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { name: "Antonio", sample: "/spanish/antonio_m.mp3", gender: "male", dataset: "carlfm_low", isDefault: true },

        ]
    },
    {
        language: "Persian",
        code: "fa",
        flag: "🇮🇷",
        isMaleAvailable: false,
        isFemaleAvailable: true,
        speakers: [
            { name: "Pilar", sample: "/persian/pilar_f.mp3", gender: "female", dataset: "haaniye_low", isDefault: true },
        ]
    },
    {
        language: "French",
        code: "fr_FR",
        flag: "🇫🇷",
        isMaleAvailable: true,
        isFemaleAvailable: true,
        speakers: [
            { id: 0, name: "Martina", speaker: "ezwa", sample: "/french/martina_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 1, name: "Victoria", speaker: "nadine_eckert_boulet", sample: "/french/victoria_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false },
            { id: 2, name: "Gabriel", speaker: "bernard", sample: "/french/gabriel_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { id: 3, name: "Martin", speaker: "zeckou", sample: "/french/martin_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { id: 4, name: "Andres", speaker: "gilles_g_le_blanc", sample: "/french/andres_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { name: "Natalia", sample: "/french/natalia_f.mp3", gender: "female", dataset: "siwis_low", isDefault: true },
            { name: "Javier", sample: "/french/javier_m.mp3", gender: "male", dataset: "tom_low", isDefault: true }
        ]
    },
    {
        language: "Gujarati",
        code: "gu_IN",
        flag: "🇮🇳",
        isMaleAvailable: true,
        isFemaleAvailable: true,
        speakers: [
            { id: 0, name: "Mona", speaker: "cmu_indic_guj_dp", sample: "/gujarati/mona_f.mp3", gender: "female", dataset: "cmu-indic_low", isDefault: false },
            { id: 1, name: "Parth", speaker: "cmu_indic_guj_ad", sample: "/gujarati/parth_m.mp3", gender: "male", dataset: "cmu-indic_low", isDefault: false },
            { id: 2, name: "Kinjal", speaker: "cmu_indic_guj_kt", sample: "/gujarati/kinjal_f.mp3", gender: "female", dataset: "cmu-indic_low", isDefault: false }
        ]
    },
    {
        language: "Hungarian",
        code: "hu_HU",
        flag: "🇭🇺",
        isMaleAvailable: false,
        isFemaleAvailable: true,
        speakers: [
            { name: "Armina", sample: "/hungarian/armina_f.mp3", gender: "female", dataset: "diana-majlinger_low", isDefault: true }
        ]
    },
    {
        language: "Italian",
        code: "it_IT",
        flag: "🇮🇹",
        isMaleAvailable: true,
        isFemaleAvailable: true,
        speakers: [
            { id: 0, name: "Leonardo", speaker: "1595", sample: "/italian/leonardo_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 1, name: "Sofia", speaker: "4974", sample: "/italian/sofia_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 2, name: "Pietro", speaker: "4998", sample: "/italian/pietro_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 3, name: "Aurora", speaker: "6807", sample: "/italian/aurora_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 4, name: "Giovanni", speaker: "1989", sample: "/italian/giovanni_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 5, name: "Samuele", speaker: "2033", sample: "/italian/samuele_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 6, name: "Enea", speaker: "2019", sample: "/italian/enea_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 7, name: "Giulia", speaker: "659", sample: "/italian/giulia_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 8, name: "Ginevra", speaker: "4649", sample: "/italian/ginevra_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 9, name: "Nicolo", speaker: "9772", sample: "/italian/nicolo_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 10, name: "Oliver", speaker: "1725", sample: "/italian/oliver_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 11, name: "Lucas", speaker: "10446", sample: "/italian/lucas_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 12, name: "Luca", speaker: "6348", sample: "/italian/luca_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 13, name: "Vittoria", speaker: "6001", sample: "/italian/vittoria_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 14, name: "Beatrice", speaker: "9185", sample: "/italian/beatrice_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 15, name: "Alice", speaker: "8842", sample: "/italian/alice_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 16, name: "Ludovica", speaker: "8828", sample: "/italian/ludovica_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 17, name: "Mateo", speaker: "12428", sample: "/italian/mateo_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 18, name: "Sebastian", speaker: "8181", sample: "/italian/sebastian_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 19, name: "Emma", speaker: "7440", sample: "/italian/emma_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 20, name: "Anna", speaker: "8207", sample: "/italian/anna_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 21, name: "Camilla", speaker: "277", sample: "/italian/camilla_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 22, name: "Chiara", speaker: "5421", sample: "/italian/chiara_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 23, name: "HuxlLeoey", speaker: "12804", sample: "/italian/huxlLeoey_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 24, name: "Julian", speaker: "4705", sample: "/italian/julian_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 25, name: "Giorgia", speaker: "7936", sample: "/italian/giorgia_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 26, name: "Bianca", speaker: "844", sample: "/italian/bianca_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 27, name: "Nicole", speaker: "6299", sample: "/italian/nicole_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 28, name: "Anthony", speaker: "644", sample: "/italian/anthony_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 29, name: "Greta", speaker: "8384", sample: "/italian/greta_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 30, name: "Santiago", speaker: "1157", sample: "/italian/santiago_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 31, name: "Gaia", speaker: "7444", sample: "/italian/gaia_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 32, name: "Martina", speaker: "643", sample: "/italian/martina_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 33, name: "Azzurra", speaker: "4971", sample: "/italian/azzurra_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 34, name: "Olivia", speaker: "4975", sample: "/italian/olivia_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 35, name: "Christopher", speaker: "6744", sample: "/italian/christopher_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { id: 36, name: "Isabella", speaker: "8461", sample: "/italian/isabella_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 37, name: "Mia", speaker: "7405", sample: "/italian/mia_f.mp3", gender: "female", dataset: "mls_low", isDefault: false },
            { id: 38, name: "Adrian", speaker: "5010", sample: "/italian/adrian_m.mp3", gender: "male", dataset: "mls_low", isDefault: false },
            { name: "Diego", sample: "/italian/diego_m.mp3", gender: "male", dataset: "riccardo-fasol_low", isDefault: true }

        ]
    },
    {
        language: "Korean",
        code: "ko_KO",
        flag: "🇰🇷",
        isMaleAvailable: false,
        isFemaleAvailable: true,
        speakers: [
            { name: "Haeun", sample: "/korean/haeun_f.mp3", gender: "female", dataset: "kss_low", isDefault: true }
        ]
    },
    {
        language: "Nepali",
        code: "ne_NP",
        flag: "🇳🇵",
        isMaleAvailable: false,
        isFemaleAvailable: true,
        speakers: [
            { id: 0, name: "Shambhav", speaker: "0546", sample: "/nepali/shambhav_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 1, name: "Yahnaa", speaker: "3614", sample: "/nepali/yahnaa_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 2, name: "Samixya", speaker: "2099", sample: "/nepali/samixya_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 3, name: "Liv", speaker: "3960", sample: "/nepali/liv_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 4, name: "Roshika", speaker: "6834", sample: "/nepali/roshika_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 5, name: "Sabina", speaker: "7957", sample: "/nepali/sabina_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 6, name: "Subu", speaker: "6329", sample: "/nepali/subu_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 7, name: "Reshma", speaker: "9407", sample: "/nepali/reshma_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 8, name: "riyana", speaker: "6587", sample: "/nepali/riyana_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 9, name: "Rasila", speaker: "0258", sample: "/nepali/rasila_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 10, name: "Reeya", speaker: "2139", sample: "/nepali/reeya_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 11, name: "Abra", speaker: "5687", sample: "/nepali/abra_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 12, name: "Soneeya", speaker: "0283", sample: "/nepali/soneeya_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 13, name: "palisha", speaker: "3997", sample: "/nepali/palisha_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 14, name: "vivi", speaker: "3154", sample: "/nepali/vivi_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 15, name: "Prapti", speaker: "0883", sample: "/nepali/prapti_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 16, name: "Aarju", speaker: "2027", sample: "/nepali/aarju_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false },
            { id: 17, name: "Eyrum", speaker: "0649", sample: "/nepali/eyrum_f.mp3", gender: "female", dataset: "ne-google_low", isDefault: false }
        ]

    },
    {
        language: "Dutch",
        code: "nl",
        flag: "🇳🇱 ",
        isMaleAvailable: true,
        isFemaleAvailable: false,
        speakers: [
            { name: "Aart", sample: "/dutch/aart_m.mp3", gender: "male", dataset: "bart-de-leeuw_low", isDefault: true },
            { name: "Aya", sample: "/dutch/aya_m.mp3", gender: "male", dataset: "flemishguy_low", isDefault: true },
            { name: "Adrianus", sample: "/dutch/adrianus_m.mp3", gender: "male", dataset: "nathalie_low", isDefault: true },
            { name: "Abbe", sample: "/dutch/abbe_m.mp3", gender: "male", dataset: "pmk_low", isDefault: true },
            { name: "Dirk", sample: "/dutch/dirk_m.mp3", gender: "male", dataset: "rdh_low", isDefault: true }
        ]
    },
    {
        language: "Polish",
        code: "pl_PL",
        flag: "🇵🇱",
        isMaleAvailable: true,
        isFemaleAvailable: true,
        speakers: [

            { id: 0, name: "Antoni", speaker: "piotr_nater", sample: "/polish/antoni_m.mp3", gender: "male", dataset: "m-ailabs_low", isDefault: false },
            { id: 1, name: "Anna", speaker: "nina_brown", sample: "/polish/anna_f.mp3", gender: "female", dataset: "m-ailabs_low", isDefault: false }
        ]
    },
    {
        language: "Russian",
        code: "ru_RU",
        flag: "🇷🇺",
        isMaleAvailable: true,
        isFemaleAvailable: false,
        speakers: [
            { id: 0, name: "Victor", speaker: "hajdurova", sample: "/russian/victor_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 1, name: "Boris", speaker: "minaev", sample: "/russian/boris_m.mp3", gender: "male", dataset: "multi_low", isDefault: false },
            { id: 2, name: "Mikhail", speaker: "nikolaev", sample: "/russian/mikhail_m.mp3", gender: "male", dataset: "multi_low", isDefault: false }
        ]

    },
]

