'use client'
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DragableView from "./DragableView";
import { useStore } from "../../../../utility/hooks/UseStore";
import arrow from "../../../../assets/icons/timeline_arrow.svg";

const TimeFrameView = observer((props) => {

    const mainStore = useStore()
    const store = mainStore.videoStore

    const { element } = props;
    const disabled = element.type === "video";
    const isSelected = store.selectedElement?.id === element.id;
    // const bgColorOnSelected = isSelected ? "bg-slate-800" : "bg-slate-600" #F36870;
    let bgColorOnSelected = "bg-[#4034A7]";
    if (element.type === "video") {
        bgColorOnSelected = `${`bg-[#D7A51F] border border-x-[#D7A51F]  ${isSelected ? 'border-y-[#D7A51F]' : 'border-y-[0px]'} `}`;
    } else if (element.type === "text") {
        bgColorOnSelected = `${`bg-[#F36870] border border-x-[#D4585F]  ${isSelected ? 'border-y-[#D4585F]' : 'border-y-[0px]'}`}`;
    } else if (element.type === "button") {
        bgColorOnSelected = `${`bg-[#7A0BC0] border border-x-[#6607A1] ${isSelected ? 'border-y-[#6607A1]' : 'border-y-[0px]'}`}`;
    } else if (element.type === "audio") {
        bgColorOnSelected = `${`bg-[#14AE87] border border-x-[#138B67] ${isSelected ? 'border-y-[#138B67]' : 'border-y-[0px]'}`}`;
    }

    const disabledCursor = disabled ? "cursor-no-drop" : "cursor-ew-resize";

    // useEffect(() => {
    // // store.setSelectedElement(element);
    // if (store.selectedElement?.type === "audio") {
    // store.setSelectedMenuOption("Speech Sidepanel")
    // } else if (store.selectedElement?.type === "text") {
    // store.setSelectedMenuOption("TextPanel")
    // } else if (store.selectedElement?.type === "button") {
    // store.setSelectedMenuOption("Button Link")
    // } else if (store.selectedElement?.type === "video") {
    // store.setSelectedMenuOption("video")
    // }
    // }, [store.selectedElement])
    
    return (
        <div onClick={() => {
            store.setSelectedElement(element);
            if (store.selectedElement?.type === "audio") {
                store.setSelectedMenuOption("Speech Sidepanel")
            } else if (store.selectedElement?.type === "text") {
                store.setSelectedMenuOption("TextPanel")
            } else if (store.selectedElement?.type === "button") {
                store.setSelectedMenuOption("Button Link")
            } else if (store.selectedElement?.type === "video") {
                store.setSelectedMenuOption("video")
            }

        }} key={element.id} className={`relative w-full h-[25px] 2xl:h-[30px] mb-1 rounded  ${isSelected ? "bg-[#0D0D11]" : ""
            }`} >
            {
                (element.type !== "video" && element.type !== "audio") &&
                <DragableView
                    className="z-10 h-[100%]"
                    value={element.timeFrame.start}
                    total={store.maxTime}
                    disabled={disabled}
                    onChange={(value) => {
                        // if (value >= element.timeFrame.end - 400) {
                        //     value = element.timeFrame.end - 400; // Snap to just before the end time
                        //   }
                        //   console.log(value - 400)
                        store.updateEditorElementTimeFrame(element, {
                            start: value,
                        });
                    }}
                >
                    {/* <div className={`mt-[calc(41px/2)] translate-y-[-50%] transform translate-x-[-50%] ${disabledCursor}`} > */}
                    <div className={`h-[100%] flex justify-around items-center absolute right-[-18px] w-[15px] top-0 ${disabledCursor}`} >
                        <img src={arrow} alt="arrow" width={7} />
                    </div>
                </DragableView>
            }
            <DragableView
                // className={disabled ? "cursor-no-drop" : "cursor-col-resize"}
                value={element.timeFrame.start}
                disabled={disabled}
                style={{ width: `${((element.timeFrame.end - element.timeFrame.start) / store.maxTime) * 100}%` }}
                total={store.maxTime}
                onChange={(value) => {
                    const { start, end } = element.timeFrame;
                    element.type !== "video" && store.updateEditorElementTimeFrame(element, { start: value, end: value + (end - start), })
                }}
            >
                <div className={`${bgColorOnSelected} flex items-center  cursor-pointer overflow-hidden truncate select-none h-full text-white text-xs px-2 leading-[25px] rounded border-y-4 border-x-[20px]`} >
                    {element.type === "video" ? "Video" : element.type === "text" ? element.properties.text ? element.properties.text : 'Text' : element.type === "button" ? element.properties.text ? element.properties.text : 'Button'  : "Audio"}
                </div>
            </DragableView>
            {(element.type !== "video" && element.type !== "audio") &&
                <DragableView
                    className="z-10"
                    disabled={disabled}
                    value={element.timeFrame.end}
                    total={store.maxTime}
                    onChange={(value) => {
                        // if (value <= element.timeFrame.start + 400) {
                        //     value = element.timeFrame.start + 400; // Snap to just after the start time
                        //   }
                        store.updateEditorElementTimeFrame(element, {
                            end: value,
                        });
                    }}
                >
                    <div className={`h-[100%] flex justify-around items-center absolute left-[-18px] w-[15px] top-0 rotate-180 ${disabledCursor} `} >
                        <img src={arrow} alt="arrow" width={7} />
                    </div>
                </DragableView>
            }
        </div>
    );
});

export default TimeFrameView;

