import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../utility/hooks/UseStore";
import SeekPlayer from "./timelineRelated/SeekPlayer";
import video_icon from "../../../assets/icons/video.svg";
import text_icon from "../../../assets/icons/text.svg";
import button_icon from "../../../assets/icons/button.svg";
import text_to_speech_icon from "../../../assets/icons/text_to_speech.svg";
import lock_open_icon from "../../../assets/icons/lock_open.svg";
import close_eye_icon from "../../../assets/icons/close_eyes.svg";
import eye_icon from "../../../assets/icons/eye.svg";
import delete_icon from "../../../assets/icons/delete.svg";
import ScaleRangeInput from "./timelineRelated/ScaleRangeInput";
import TimeFrameView from "./timelineRelated/TimeFrameView";

const TimeLine = observer(({ showTimeline , setOpenDeleteModal , setElementType , setElementId}) => {

    const mainStore = useStore();
    const store = mainStore.videoStore;


    const percentOfCurrentTime = (store.currentTimeInMs / store.maxTime) * 100;

    const MARKINGS = [
        {
            interval: 5000,
            color: "#3B3B4F",
            size: 16,
            width: 1,
        },
        {
            interval: 1000,
            color: "#3B3B4F",
            size: 8,
            width: 1,
        },
    ];
    // this is for size finder of user desktop
    const [sizeFinder, setSizeFinder] = useState("")

    useEffect(() => {
        if (window.innerWidth) {
            updateWindowDimensions()
        }
        window.addEventListener('resize', updateWindowDimensions)
    }, [])


    function updateWindowDimensions() {
        if (window.innerWidth > 1024 && window.innerWidth < 1280) {
            setSizeFinder("lg")
        } else if (window.innerWidth > 1280 && window.innerWidth < 18000) {
            setSizeFinder("xl")
        } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
            setSizeFinder("md")
        } else if (window.innerWidth > 640 && window.innerWidth < 768) {
            setSizeFinder("sm")
        } else if (window.innerWidth > 0 && window.innerWidth < 640) {
            setSizeFinder("default")
        }
    }

    const editorElements = store.editorElements?.slice()?.reverse()
    return (
        <div className="w-[100%] flex flex-col ">
            <div className="grid grid-cols-12  border-b border-[#2C2D3C]">
                <p className="col-span-3 2xl:col-span-2 font-Inter font-normal text-sm text-[#ABABC0] px-[12px] py-[10px] w-full border-r border-[#2C2D3C] text-center h-[38px] 2xl:h-[45px]">Layers</p>
                <div className="col-span-9 2xl:col-span-10">
                    <SeekPlayer showTimeline={showTimeline}/>
                </div>
            </div>
            <div className={`grid grid-cols-12 overflow-auto duration-1000 ${showTimeline ? "h-[145px] 2xl:h-[200px] showTimeLine" : "max-h-[0px] hideTimeLine"}  scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent`}>
                {/* element */}
                <div className="col-span-3 2xl:col-span-2">
                    <p className="bg-[#17171E] font-Inter font-normal text-sm text-[#ABABC0] px-[12px]  flex items-center justify-center w-full border-r border-b border-[#2C2D3C] text-center h-[35px]">Impact Layers</p>
                    {editorElements.map((element) => (
                        <div
                            className={` flex px-[12px] flex-row justify-between items-center bg-[#272836] w-full border-b border-[#2C2D3C] py-[0px] h-[29px] 2xl:h-[34px]`}
                            key={element?.id}
                            onClick={() => {
                                store.setSelectedElement(element);
                            }}
                        >
                            {element?.type === "video" && <img src={video_icon} alt="VideoIcon" className="w-[16px] h-auto object-contain " />}
                            {element?.type === "text" && <img src={text_icon} alt="TextIcon" className="w-[16px] h-auto object-contain " />}
                            {element?.type === "button" && <img src={button_icon} alt="BtnIcon" className="w-[20px] h-auto object-contain " />}
                            {element?.type === "audio" && <img src={text_to_speech_icon} alt="TTSIcon" className="w-[20px] h-auto object-contain " />}
                            <div>
                                {element && element.type === "video" ? (
                                    <video
                                        className="opacity-0 max-w-[0px] max-h-[0px]"
                                        src={element.properties.src}
                                        onLoad={() => {
                                            store.refreshElements();
                                        }}
                                        onLoadedData={() => {
                                            store.refreshElements();
                                        }}
                                        height={20}
                                        width={20}
                                        id={element.properties.elementId}
                                    ></video>
                                ) : null}
                                {element?.type === "image" ? (
                                    <img
                                        className="opacity-0 max-w-[20px] max-h-[20px]"
                                        src={element.properties.src}
                                        onLoad={() => {
                                            store.refreshElements();
                                        }}
                                        alt="img"
                                        onLoadedData={() => {
                                            store.refreshElements();
                                        }}
                                        height={20}
                                        width={20}
                                        id={element.properties.elementId}
                                    ></img>
                                ) : null}
                                {element?.type === "audio" ? (
                                    <audio
                                        className="opacity-0 max-w-[20px] max-h-[20px]"
                                        src={element.properties.src}
                                        onLoad={() => {
                                            store.refreshElements();
                                        }}
                                        onLoadedData={() => {
                                            store.refreshElements();
                                        }}
                                        id={element.properties.elementId}
                                    ></audio>
                                ) : null}
                            </div>


                            <div>
                                {/* <button className={`${element?.type === "video" ? "cursor-not-allowed opacity-[0.2]" : "cursor-pointer"} m-[10px] 2xl:m-[10px] outline-none`}><img src={lock_open_icon} className=" w-[18px] h-auto object-contain 2xl:w-auto 2xl:object-none" alt="Lock" /></button> */}
                                <button onClick={(e) => {
                                    store.isVisible(element.id, element.isVisible === true ? false : true)
                                }}
                                    className={`${element?.type === "video" ? "cursor-not-allowed opacity-[0.2]" : "cursor-pointer"} m-[10px] 2xl:m-[10px] outline-none`}
                                    disabled={element?.type === 'video'}
                                >
                                    {(element?.timeFrame.start >= store.maxTime) ? <img src={close_eye_icon} alt="CloseEye" className=" w-[20px] h-auto object-contain 2xl:w-auto 2xl:object-none" /> : <img src={eye_icon} className=" w-[20px] h-auto object-contain 2xl:w-auto 2xl:object-none" alt="Eye" />}

                                </button>
                                <button
                                    className={`${element?.type === "video" ? "cursor-not-allowed opacity-[0.2]" : "cursor-pointer"} m-[10px] 2xl:m-[10px] outline-none`}
                                    onClick={(e) => {
                                        setOpenDeleteModal(true)
                                        setElementType(element.type)
                                        setElementId(element.id);
                                        // store.refreshElements();
                                        // e.preventDefault();
                                        // e.stopPropagation();
                                        // store.setSelectedMenuOption("NoSelectedItem")
                                    }}
                                    disabled={element?.type === 'video'}
                                >
                                    <img src={delete_icon} className=" w-[14px] h-auto object-contain 2xl:w-auto 2xl:object-none" alt="Delete" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {/* timeline  */}
                <div className="pr-[20px] col-span-9 2xl:col-span-10">
                    <div id='scrollElement22' className="w-[calc(100% - 30px)]  overflow-x-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent">
                        <div style={{ width: `${store.timeLineZoom}%` }} className="">
                            <div className=" border-b border-[#2C2D3C]">
                                <ScaleRangeInput
                                    max={store.maxTime}
                                    value={store.currentTimeInMs}
                                    onChange={(value) => {
                                        store.handleSeek(value);
                                    }}
                                    height={35}
                                    markings={MARKINGS}
                                    backgroundColor="#17171E"
                                    className="h-[35px]"
                                />
                                {/* <p className={`text-[#fff] absolute z-50 top-[-5px] `} style={{left: `${percentOfCurrentTime}%`,}}>{(store.currentTimeInMs / 1000).toFixed(2)}</p> */}
                            </div>
                            <div className="flex-1 relative">
                                {editorElements.map((element) => {
                                    if (element.timeFrame.start >= store.maxTime) {
                                        return (<div key={element.id} className="w-[100%] bg-[#0D0D11] h-[30px] 2xl:h-[35px]"></div>)
                                    } else {
                                        return (<TimeFrameView key={element.id} element={element} />)
                                    }
                                })}
                                <div
                                    className="w-[2px] bg-[#FFFFFF] absolute top-0 bottom-0 ml-[5px]"
                                    style={{
                                        left: `${percentOfCurrentTime}%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
});

export default TimeLine;
